import React, { Component, Fragment } from 'react';
import { graphql, compose, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Redirect, Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'lib/moment';
import FlatButton from 'material-ui/FlatButton';

import { utils, userMeta } from 'lib/utils';
import { localStorage } from 'lib/storage';
import { ga, reddit } from 'lib/analytics';
import { BOOKING_TYPE } from 'lib/data/constants';

import fetchUserById from 'data/queries/user/fetchUserById';
import participantSessionsByBooking from 'data/queries/booking/participantSessionsByBooking';

import updateMe from 'data/mutations/updateMe';
import createBookingSubmission from 'data/mutations/createBookingSubmission';
import participantSaveAvailability from 'data/mutations/participantSaveAvailability';
import participantCancelSession from 'data/mutations/participantCancelSession';
import { bookingSubmissionFieldsFragment } from 'data/fragments/bookingSubmissionFields';

import { Header, Modal, Wrapper, LoadingOverlay, Button, UserAgentTest } from 'components/common';

import ScreenerPrimer from './screener-primer-view';
import PopupContentsQuant from './apply-quant-view';
import PopupContentsDiaryStudy from './apply-diary-study-view';
import InContextLocationView from './in-context-location-view';
import AgreementConsentScreen from './apply-agreement-consent-view';

import BookingScreenerView from '../screener/bookingScreenerView';
import BookingScheduleSessionSelectView from '../schedule/bookingScheduleSessionSelectView';
import BookingScheduleConfirmView from '../schedule/bookingScheduleConfirmView';
import { withKinde } from '../../../AuthWrapper';

import './styles/apply.scss';
import { withMonocle } from '../../../MonocleWrapper';

const findBookingSubmission = gql`
  query FindBookingSubmission($userId: ID, $bookingId: ID) {
    findBookingSubmission(userId: $userId, bookingId: $bookingId) {
      ...bookingSubmissionFields
      participant_is_excluded
    }
  }
  ${bookingSubmissionFieldsFragment}
`;

const PayoutDetailsLink = ({ children }) => {
  return (
    <a
      href="#menu=payout"
      className="link"
      onClick={e => {
        e.preventDefault();
        utils.setMenuState('menu=payout');
      }}
    >
      {children}
    </a>
  );
};


class BookingApply extends Component {
  constructor(props) {
    // console.log('BookingScheduleView: constructor');
    super(props);

    this.state = {
      loading: false,
      error: null,
      availableSessions: [],
      in_context: {},
      agreement_consent: false,
    };

    this.popupContents = this.popupContents.bind(this);
    this.closeScreener = this.closeScreener.bind(this);
    // this.sessionParticipantCount = this.sessionParticipantCount.bind(this);

    // if (this.state.availableSessions.length > 0) {
    //     console.log('sessionParticipantCount', this.state.availableSessions.map(session => session._id));
    //     this.sessionParticipantCount(this.state.availableSessions.map(session => session._id));
    // }
    this.updateStateFromProps = this.updateStateFromProps.bind(this);
    this.updateUser = this.updateUser.bind(this);
  }

  componentWillMount() {
    this.updateStateFromProps(this.props);
  }

  componentWillReceiveProps(newProps) {
    this.updateStateFromProps(newProps);
  }

  updateStateFromProps(newProps) {
    if (newProps.participantSessionsByBooking.participantSessionsByBooking) {
      const availableSessions = newProps.participantSessionsByBooking.participantSessionsByBooking.filter(
        booking_participant => booking_participant.cancel === 0 && booking_participant.status === 3,
      );
      // console.log({ availableSessions });
      this.setState({
        availableSessions,
        selectedSessionIds: availableSessions.map(booking_participant => booking_participant.session._id),
      });
    }
    if (_.get(newProps, 'fetchUserById.userByID') && !this.state.user) {
      this.setState({
        user: newProps.fetchUserById.userByID,
      });
    }
  }

  updateUser(user, then) {
    this.props.updateUser(user).then(then);
  }

  closeScreener() {
    // this.props.fetchUserById.refetch({ variables: this.props.queryVariables.fetchUserById });
    // settings.remote.setup_check
    if (
      _.get(this.props, 'match.url', '').match(/message\/in-review$/) &&
      _.get(this.props, 'fetchBookingById.bookingByID.type') === BOOKING_TYPE.REMOTE &&
      !_.get(this.props, 'fetchUserById.userByID.settings.remote.setup_check')
    ) {
      this.props.history.push(`${this.props.bookingBaseUrl}/apply/message/remote-setup-check`);
      return false;
    }
    if (_.get(this.props, 'match.url', '').match(/message\/in-review$/) && !_.get(this.state.user, 'meta.feedback_sample.recording_url')) {
      this.props.history.push(`${this.props.bookingBaseUrl}/apply/message/feedback-sample`);
      return false;
    }
    this.props.refetchUserDetails();
    this.props.history.push(this.props.bookingBaseUrl);
  }

  popupContents() {
    const booking = this.props.fetchBookingById.bookingByID;

    if (
      !this.props.isPreview &&
      (!_.get(this.props, 'fetchUserById.userByID.location.latitude') || !_.get(this.props, 'fetchUserById.userByID.location.longitude'))
    ) {
      localStorage.delete('userBasicDetails');
      localStorage.save('tagetPath', `/booking/${booking._id}/apply/screener`);
      return <Redirect to="/onboard" />;
    }

    if (
      !this.props.isPreview &&
      !_.get(booking, 'config.criteria.locations.ParticipantIsEligible') &&
      !_.get(this.props, 'match.path').match(/\/apply\/message\//)
    ) {
      if (_.get(booking, 'config.criteria.locations.ParticipantIsEligible') === null) {
        utils.window.location.reload();
        return null;
      }
      return <Redirect to={`${this.props.bookingBaseUrl}/apply/message/wrong-location`} />;
    }

    if (!this.props.isPreview && _.get(booking, 'participant_is_excluded') && !_.get(this.props, 'match.url').match(/error\/previous-attendance/)) {
      return <Redirect to={`${this.props.bookingBaseUrl}/apply/error/previous-attendance`} />;
    }

    if (
      !this.props.isPreview &&
      !this.state.agreement_consent &&
      _.get(booking, 'config.participant_agreement.type') === 1 &&
      !_.get(this.props, 'match.url').match(/agreement-consent/) &&
      !_.get(this.props, 'match.url').match(/\/error\//)
    ) {
      if (_.get(this.props, 'findBookingSubmission.findBookingSubmission.0')) {
        if (!this.props.findBookingSubmission.findBookingSubmission[0].eligibility && !_.get(this.props, 'match.url').match(/error\/ineligible/)) {
          return <Redirect to={`${this.props.bookingBaseUrl}/apply/error/ineligible`} />;
        }
      } else {
        return <Redirect to={`${this.props.bookingBaseUrl}/apply/agreement-consent`} />;
      }
    }

    if (booking.type === 3 && _.get(this.props, 'match.params.page', '').match(/^online-task/)) {
      return (
        <PopupContentsQuant
          booking={booking}
          user={_.get(this.props, 'fetchUserById.userByID') || {}}
          bookingSubmission={_.get(this.props.findBookingSubmission, 'findBookingSubmission.0', null)}
          bookingParticipant={_.get(this.props.participantSessionsByBooking, 'participantSessionsByBooking.0', null)}
          {..._.pick(this.props, [
            'bookingBaseUrl',
            'history',
            'isPreview',
            'match',
            'queryVariables',
            //
            // // queries
            'participantSessionsByBooking',
            // 'fetchBookingById',
            // 'fetchUserById',
            'findBookingSubmission',
            // 'refetchUserDetails',
            // 'reloadBooking',
            //
            // // mutations
            // 'createBookingSubmission',
            // 'participantCancelSession',
            'participantSaveAvailability',
            // 'saveAvailabilityQuantitative',
            // 'updateUser',
          ])}
        />
      );
    }
    if (booking.type === 4 && _.get(this.props, 'match.params.page', '').match(/^longitudinal/)) {
      return (
        <PopupContentsDiaryStudy
          booking={booking}
          user={_.get(this.props, 'fetchUserById.userByID') || {}}
          bookingSubmission={_.get(this.props.findBookingSubmission, 'findBookingSubmission.0', null)}
          bookingParticipant={_.get(this.props.participantSessionsByBooking, 'participantSessionsByBooking.0', null)}
          {..._.pick(this.props, [
            'bookingBaseUrl',
            'history',
            'isPreview',
            'match',
            'queryVariables',
            'participantSessionsByBooking',
            'findBookingSubmission',
            'participantSaveAvailability',
          ])}
        />
      );
    }
    if (booking.status !== 1 && !this.props.isPreview) {
      const url = `${this.props.bookingBaseUrl}`;
      return <Redirect to={url} />;
    }
    if (
      !this.props.isPreview &&
      _.get(this.props, 'match.params.page') &&
      _.get(this.props, 'match.params.page') !== 'error/previous-attendance' &&
      _.get(booking, 'config.criteria.exclude_previous_participant') &&
      _.get(this.props.findBookingSubmission, 'findBookingSubmission.0.participant_is_excluded')
    ) {
      const url = `${this.props.bookingBaseUrl}/apply/error/previous-attendance`;
      return <Redirect to={url} />;
    }

    const hideOpportunity = _.has(this.props.fetchBookingById, 'bookingByID._id') && (
      <p>
        <Link
          to="/opportunities"
          className="link"
          style={{ color: 'inherit', opacity: 0.5 }}
          onClick={() => {
            utils.hideOpportunity(this.props.fetchBookingById.bookingByID._id);
          }}
        >
          Hide this opportunity
        </Link>
      </p>
    );

    switch (_.get(this.props, 'match.params.page')) {
      case 'screener': {
        if (!this.props.isPreview && _.get(this.props.findBookingSubmission, 'findBookingSubmission.length', 0) > 0) {
          // console.log('REDIRECTING screener => availability');
          if (booking.type === 3) {
            const url = `${this.props.bookingBaseUrl}/apply/online-task`;
            return <Redirect to={url} />;
          }
          const url = `${this.props.bookingBaseUrl}/apply/availability`;
          return <Redirect to={url} />;
        }

        if (!this.props.isPreview && !_.get(this.state.user, 'contact.phone.verification.mobile')) {
          utils.privateRouteInterrupt(window.location.pathname);
          return <Redirect to="/onboard" />;
        }

        if (!this.props.isPreview && _.get(booking, 'config.in_context.location_type') && !_.get(this.state, 'in_context.location.street1')) {
          return <Redirect to={`${this.props.bookingBaseUrl}/apply/in-context`} />;
        }

        const { mediumThree } = userMeta;
        if (
          !_.get(this.state.user, 'meta.work.status') ||
          _.get(this.state.user, 'meta.work.status.fulltime') ||
          _.get(this.state.user, 'meta.work.status.parttime')
        ) {
          if (mediumThree.indexOf('meta.work._industry_id') === -1) {
            mediumThree.push('meta.work._industry_id');
          }
          if (mediumThree.indexOf('meta.work.employment_type') === -1) {
            mediumThree.push('meta.work.employment_type');
          }
        }

        if (_.get(booking, 'config.participant_category') === 2 && mediumThree.indexOf('meta.social.linkedin.profile_url') === -1) {
          mediumThree.push('meta.social.linkedin.profile_url');
        }

        const userHasPropsResult = {};
        if (this.props.isPreview) {
          userHasPropsResult.missing = [];
        } else {
          utils.objectHasProps(this.state.user, mediumThree, userHasPropsResult);
        }

        const unansweredScreeners = (this.props.fetchBookingById.bookingByID.config.question || []).filter(
          s => !s.previous_responses || s.previous_responses.length === 0,
        );
        const screenerPageCount = unansweredScreeners.length + userHasPropsResult.missing.length;

        if (screenerPageCount > 0 && !this.state.screenerPrimed) {
          return (
            <ScreenerPrimer
              booking={booking}
              onPrimed={() => {
                this.setState({ loading: false, screenerPrimed: true });
              }}
            />
          );
        }

        const screener = (
          <BookingScreenerView
            booking={booking}
            page={this.props.match.params.progress || 0}
            pageCount={screenerPageCount}
            history={this.props.history}
            mediumThree={userHasPropsResult.missing || []}
            fetchUserById={_.get(this.props, 'fetchUserById')}
            findBookingSubmission={this.props.findBookingSubmission}
            updateUser={this.updateUser}
            getMonocleBundle={this.props.getMonocleBundle}
            createBookingSubmission={this.props.createBookingSubmission}
            createBookingSubmissionThen={createBookingSubmissionResult => {
              reddit.track('SignUp');
              // if (_.get(createBookingSubmissionResult, 'data.createBookingSubmission.eligibility', 0) < 1) {
              //     return;
              // }
              if (_.get(createBookingSubmissionResult, 'data.createBookingSubmission.participant_is_excluded')) {
                return;
              }

              if (
                booking.type === 3 &&
                (_.get(booking, 'config.options.review_submission') || _.get(createBookingSubmissionResult, 'data.createBookingSubmission.eligibility') === 1)
              ) {
                this.props
                  .participantSaveAvailability(
                    booking._id,
                    booking.session.map(session => session._id),
                  )
                  .then(() => {
                    this.props.participantSessionsByBooking.refetch({ _user_id: localStorage.get('connectedParticipant') });
                    this.props.history.replace(`${this.props.bookingBaseUrl}/apply/online-task`);
                  })
                  .catch(error => {
                    const errorMessage = _.get(error, 'graphQLErrors.0.message', 'Failed to save availability');
                    this.setState({
                      loading: false,
                      error: errorMessage,
                    });
                  });
              }
            }}
            agreementConsent={this.state.agreement_consent}
            isPreview={this.props.isPreview}
            bookingBaseUrl={this.props.bookingBaseUrl}
            in_context={this.state.in_context}
          />
        );
        return screener;
      }
      case 'availability': {
        if (booking.type === 3) {
          const url = `${this.props.bookingBaseUrl}/apply/online-task`;
          return <Redirect to={url} />;
        }
        if (booking.type === 4) {
          const url = `${this.props.bookingBaseUrl}/apply/longitudinal`;
          return <Redirect to={url} />;
        }
        // Select availability & join sessions (available)
        // if they're already signed up to any sessions with a status other than 3, redirect
        if (_.get(this.props.findBookingSubmission, 'findBookingSubmission.length', 0) === 0) {
          const url = `${this.props.bookingBaseUrl}/apply/screener`;
          return <Redirect to={url} />;
        }
        if (
          _.filter(this.props.participantSessionsByBooking.participantSessionsByBooking, booking_participant => booking_participant.status !== 3).length > 0
        ) {
          const url = `${this.props.bookingBaseUrl}/apply/complete`;
          return <Redirect to={url} />;
        }
        let timezoneMessage = null;
        if (booking.config.timezone && booking.config.timezone !== moment.tz.guess()) {
          const zone = moment.tz.zone(booking.config.timezone);
          const zones = `(${_.uniq(zone.abbrs).join('/')})`;
          timezoneMessage = (
            <div className="fontSmall">
              <p>
                <em>
                  Please note: Times above are displayed in your current timezone, but this opportunity was created in a different timezone{' '}
                  {zone && zone.abbrs ? zones : ''}
                </em>
              </p>
            </div>
          );
        }
        if (!this.state.selectedSessionIds) {
          return <LoadingOverlay />;
        }
        const noAvailabilityLink = `${this.props.bookingBaseUrl}/apply/message/no-availability`;
        return (
          <Wrapper
            className="applyPage availability"
            // footerContents={(
            //
            // )}
          >
            <h1>What times are you available?</h1>
            <p>Choose as many as you like</p>
            <BookingScheduleSessionSelectView
              sessions={_.filter(
                booking.session,
                session =>
                  (session.status || 1) === 1 &&
                  session.start > moment().valueOf() &&
                  !_.find(
                    this.props.participantSessionsByBooking.participantSessionsByBooking,
                    booking_participant =>
                      booking_participant.session._id === session._id &&
                      (booking_participant.cancel ? booking_participant.cancel !== 0 && booking_participant.cancel !== 3 : false),
                  ),
              )}
              defaultChecked={this.state.selectedSessionIds}
              selectionChangeCallback={sessions => {
                ga.event({
                  category: 'Booking',
                  action: 'Select session time',
                  label: booking._id,
                  value: sessions.length,
                });
                this.setState({ selectedSessionIds: sessions });
              }}
            />
            <p className="mbottom20">
              <Link
                to={noAvailabilityLink}
                className="link"
                onClick={() => {
                  utils.hideOpportunity(booking._id);
                }}
              >
                I&rsquo;m not available at any of these times
              </Link>
            </p>
            {timezoneMessage}
            <Button
              label="Next"
              labelColor="#fff"
              bgColor="#FF5266"
              type="button"
              disabled={!this.state.selectedSessionIds || this.state.selectedSessionIds.length === 0}
              className="mtop40"
              onClick={() => {
                this.setState({ loading: true });
                // Add selected availability
                this.props
                  .participantSaveAvailability(booking._id, this.state.selectedSessionIds)
                  .then(() => {
                    // When successfull
                    // mixpanel.track({ event: 'Save availability', payload: { _booking_id: booking._id } });
                    this.setState({ loading: false });

                    this.props.participantSessionsByBooking.refetch({ _user_id: localStorage.get('connectedParticipant') });
                    this.props.history.push(`${this.props.bookingBaseUrl}/apply/eligibility`);
                  })
                  .catch(error => {
                    this.setState({
                      loading: false,
                      error: _.get(error, 'graphQLErrors.0.message', 'Failed to save availability'),
                    }); // TODO: TEST
                  });

                // Cancel deseleted availability
                const deseleted = _.filter(
                  this.props.participantSessionsByBooking.participantSessionsByBooking,
                  booking_participant => !_.find(this.state.selectedSessionIds, session_id => session_id === booking_participant.session._id),
                );
                if (deseleted && deseleted.length > 0) {
                  _.forEach(deseleted, booking_participant => {
                    this.props.participantCancelSession(booking_participant._id);
                  });
                }
              }}
            />
          </Wrapper>
        );
      }
      case 'in-context':
        if (!_.get(booking, 'config.in_context.location_type')) {
          return <Redirect to={`${this.props.bookingBaseUrl}/apply`} />;
        }
        if (_.get(this.props.findBookingSubmission, 'findBookingSubmission.length', 0) > 0) {
          return <Redirect to={`${this.props.bookingBaseUrl}/apply`} />;
        }
        return (
          <Wrapper className="applyPage in-context">
            <InContextLocationView
              booking={booking}
              bookingBaseUrl={this.props.bookingBaseUrl}
              history={this.props.history}
              user={_.get(this.props, 'fetchUserById.userByID')}
              onLocationUpdate={(location, location_additional) => {
                this.setState({
                  in_context: { location, location_additional },
                });
                this.props.history.push(`${this.props.bookingBaseUrl}/apply`);
              }}
            />
          </Wrapper>
        );
      case 'eligibility': {
        // if (this.state.availableSessions.length === 0) {
        //     // console.log('REDIRECTING eligibility => availability');
        //     const url = `${this.props.bookingBaseUrl}/apply/availability`;
        //     return <Redirect to={url} />;
        // }
        if (_.get(this.props.findBookingSubmission, 'findBookingSubmission.length', 0) === 0) {
          // console.log('REDIRECTING eligibility => screener');
          const url = `${this.props.bookingBaseUrl}/apply/screener`;
          return <Redirect to={url} />;
        }
        if (_.get(this.props.participantSessionsByBooking, 'participantSessionsByBooking.length', 0) === 0) {
          // console.log('REDIRECTING eligibility => availability');
          const url = `${this.props.bookingBaseUrl}/apply/availability`;
          return <Redirect to={url} />;
        }

        // It's a cherry-picking booking, don't bother with eligibility
        if (_.get(booking, 'config.options.review_submission')) {
          // console.log('REDIRECTING eligibility => message/in-review');
          const url = `${this.props.bookingBaseUrl}/apply/message/in-review`;
          return <Redirect to={url} />;
        }

        // Check if they're eligible (determined on graphql)
        const eligibility = _.get(this.props.findBookingSubmission, 'findBookingSubmission.0.eligibility', null);
        if (eligibility < 1) {
          const url = `${this.props.bookingBaseUrl}/apply/message/in-review`;
          return <Redirect to={url} />;
        }

        return <Redirect to={this.props.bookingBaseUrl} />;
      }
      case 'confirm': {
        if (this.state.availableSessions.length === 0) {
          const url = `${this.props.bookingBaseUrl}/apply/availability`;
          return <Redirect to={url} />;
        }
        if (
          _.find(
            this.props.participantSessionsByBooking.participantSessionsByBooking,
            booking_participant => booking_participant.status === 1 || booking_participant.status === 2,
          )
        ) {
          const url = `${this.props.bookingBaseUrl}/apply/complete`;
          return <Redirect to={url} />;
        }
        return (
          <div className="screenerPage">
            <BookingScheduleConfirmView
              booking={booking}
              history={this.props.history}
              availableSessions={_.sortBy(this.state.availableSessions, 'session.start')}
              participantSessionsByBooking={this.props.participantSessionsByBooking}
            />
          </div>
        );
      }
      case 'complete': {
        // handle confirmation messages (& details) for all booking_participant scenarios
        const confirmedSession = _.find(
          this.props.participantSessionsByBooking.participantSessionsByBooking,
          booking_participant => booking_participant.status === 1 && booking_participant.cancel === 0,
        );
        if (confirmedSession) {
          return (
            <div className="screenerPage">
              <Wrapper>
                <h1>Done! We&rsquo;ll see you at:</h1>
                <h2>{moment(confirmedSession.session.start).formatZoneFallback('dddd Do MMMM, h:mm A')}</h2>
                <Button
                  label="Booking details"
                  labelColor="#fff"
                  bgColor="#FF5266"
                  type="button"
                  className="mtop40"
                  onClick={this.closeScreener}
                  // className="widthAuto"
                />
              </Wrapper>
            </div>
          );
        }
        const waitlistSessions = _.filter(
          this.props.participantSessionsByBooking.participantSessionsByBooking,
          booking_participant => booking_participant.status === 2 && booking_participant.cancel === 0,
        );
        if (waitlistSessions.length > 0) {
          return (
            <div className="screenerPage">
              <Wrapper>
                <h1>Done! You&rsquo;re on the waitlist for:</h1>
                {waitlistSessions.map(booking_participant => {
                  return <h2 key={booking_participant._id}>{moment(booking_participant.session.start).formatZoneFallback('dddd Do MMMM, h:mm A')}</h2>;
                })}
                <Button
                  label="Back to booking"
                  labelColor="#fff"
                  bgColor="#FF5266"
                  type="button"
                  className="mtop40"
                  onClick={this.closeScreener}
                  // className="widthAuto"
                />
              </Wrapper>
            </div>
          );
        }
        if (_.find(this.props.participantSessionsByBooking.participantSessionsByBooking, booking_participant => booking_participant.cancel !== 0)) {
          const url = `${this.props.bookingBaseUrl}/apply/error/already-applied`;
          return <Redirect to={url} />;
        }
        const url = `${this.props.bookingBaseUrl}/apply/eligibility`;
        return <Redirect to={url} />;
      }
      case 'agreement-consent': {
        if (_.get(this.props, 'findBookingSubmission.findBookingSubmission.0')) {
          return <Redirect to={`${this.props.bookingBaseUrl}/apply/error/ineligible`} />;
        }
        return (
          <AgreementConsentScreen
            onConsentGranted={() => {
              this.setState({ agreement_consent: true });
              this.props.history.push(`${this.props.bookingBaseUrl}/apply`);
            }}
            onConsentDenied={() => {
              this.props
                .createBookingSubmission({
                  _user_id: _.get(this.props, 'fetchUserById.userByID._id'),
                  _booking_id: booking._id,
                  data: [],
                  agreement: { consent: false },
                })
                .catch(err => {
                  console.error('AgreementConsentScreen onConsentDenied', err);
                  window.location.href = `${this.props.bookingBaseUrl}/apply/error/other`;
                })
                .then(() => {
                  window.location.href = `${this.props.bookingBaseUrl}/apply/error/ineligible`;
                });
            }}
          />
        );
      }
      default: {
        const page = this.props.match.path.replace(/^.+\/(.+)\/:.+/g, '$1');
        const applyUrl = `${this.props.bookingBaseUrl}/apply/screener`;
        switch (`${page}/${_.get(this.props, 'match.params.message', '')}`) {
          case '/booking/:id/apply/':
            return <Redirect to={applyUrl} />;
          case 'error/ineligible':
            return (
              <div className="screenerPage">
                <Wrapper>
                  <h1>Sorry you&rsquo;re not eligible</h1>
                  <p>Unfortunately you don&rsquo;t meet the requirements for this opportunity.</p>
                  <p>
                    We&rsquo;d love to find a perfect match for you, so keep an eye out for{' '}
                    <Link to="/opportunities" className="link">
                      new opportunities
                    </Link>
                    .
                  </p>
                  {hideOpportunity}
                </Wrapper>
              </div>
            );
          case 'error/previous-attendance':
            return (
              <div className="screenerPage">
                <Wrapper>
                  <h1>Sorry you&rsquo;re not eligible</h1>
                  <p>
                    For this particular opportunity, the researcher is looking for people who <strong>haven&rsquo;t</strong> attended one of their sessions
                    recently.
                  </p>
                  <p>
                    Keep an eye out for{' '}
                    <Link to="/opportunities" className="link">
                      more opportunities
                    </Link>{' '}
                    as we have new ones coming up all the time.
                  </p>
                  {hideOpportunity}
                </Wrapper>
              </div>
            );
          case 'error/already-applied':
            return (
              <div className="screenerPage">
                <Wrapper>
                  <h1>Whoops</h1>
                  <p>It looks like you&rsquo;ve already applied for this session.</p>
                  <p>The best thing to do here is probably to reload the the page.</p>
                  {hideOpportunity}
                </Wrapper>
              </div>
            );
          case 'error/submit-unverified-paypal':
            return (
              <div className="screenerPage">
                <Wrapper>
                  <h1>Please verify your PayPal account</h1>
                  <p>This opportunity is not available to users with unverified PayPal accounts.</p>
                  <p><PayoutDetailsLink>Verify your PayPal account</PayoutDetailsLink></p>
                </Wrapper>
              </div>
            );
          case 'error/submit-giftpay-not-allowed':
            return (
              <div className="screenerPage">
                <Wrapper>
                  <h1>E-Gift Card is not available</h1>
                  <p>This opportunity does not allow incentive payments by e-gift card.</p>
                  <p><PayoutDetailsLink>Update your payout preferences</PayoutDetailsLink></p>
                </Wrapper>
              </div>
            );
          case 'error/other':
            return (
              <div className="screenerPage">
                <Wrapper>
                  <h1>Error</h1>
                  <p>{utils.urlParam(window.location.search, 'error') || 'We’ve encountered an error with your registration.'}</p>
                  <p>
                    If you need help, please{' '}
                    <Link to="/help" className="link">
                      contact us
                    </Link>
                  </p>
                </Wrapper>
              </div>
            );
          case 'message/in-review': {
            return (
              <div className="screenerPage">
                <Wrapper>
                  <h1>Registration submitted</h1>
                  <p>Your answers have been submitted to the researcher.</p>
                  <br />
                  <p>The researcher will look over all of the applicants.</p>
                  <p>If you’re chosen to participate, you’ll get an SMS notification with further instructions.</p>

                  <Button
                    label={_.get(this.state.user, 'meta.feedback_sample.recording_url') ? 'Finish' : 'Next'}
                    // label={`Next ${this.this.props.activePage}`}
                    labelColor="#fff"
                    bgColor="#FF5266"
                    className="btnNext mtop60"
                    // className={`btnNext mtop20${!this.state.valid ? '' : ' disabled'}`}
                    type="button"
                    onClick={this.closeScreener}
                  />
                </Wrapper>
              </div>
            );
          }
          case 'message/no-availability':
            return (
              <div className="screenerPage">
                <Wrapper>
                  <h1>Thanks for your registration</h1>
                  <p>Unfortunately there are no more times available right now.</p>
                  <p>We&rsquo;ve got your submission in case anything changes.</p>
                  <p>
                    In the meantime, see if there are any{' '}
                    <Link to="/opportunities" className="link">
                      more opportunities
                    </Link>{' '}
                    you&rsquo;d like to apply for.
                  </p>
                  {hideOpportunity}
                </Wrapper>
              </div>
            );
          case 'message/opportunity-full':
            return (
              <div className="screenerPage">
                <Wrapper>
                  <h1>Thanks for your interest</h1>
                  <p>Unfortunately it looks like this opportunity is full.</p>
                  <p>
                    In the meantime, see if there are any{' '}
                    <Link to="/opportunities" className="link">
                      more opportunities
                    </Link>{' '}
                    you&rsquo;d like to apply for.
                  </p>
                  {hideOpportunity}
                </Wrapper>
              </div>
            );
          case 'message/wrong-country': {
            const country = utils.getCountryByCode(_.get(booking, 'config.location.country'));
            return (
              <div className="screenerPage">
                <Wrapper>
                  <h1>Sorry you&rsquo;re not eligible</h1>
                  <p>This opportunity is only available to users in {_.get(country, 'name', 'a different country')}.</p>
                  <p>
                    In the meantime, see if there are any{' '}
                    <Link to="/opportunities" className="link">
                      more opportunities
                    </Link>{' '}
                    you&rsquo;d like to apply for.
                  </p>
                  {hideOpportunity}
                </Wrapper>
              </div>
            );
          }
          case 'message/wrong-location': {
            return (
              <div className="screenerPage">
                <Wrapper>
                  <h1>Sorry you&rsquo;re not eligible</h1>
                  <p>This opportunity is not available in your location.</p>
                  <p>
                    In the meantime, see if there are any{' '}
                    <Link to="/opportunities" className="link">
                      more opportunities
                    </Link>{' '}
                    you&rsquo;d like to apply for.
                  </p>
                  {hideOpportunity}
                </Wrapper>
              </div>
            );
          }
          case 'message/in-context-non-consent': {
            return (
              <div className="screenerPage">
                <Wrapper>
                  <h1>Sorry you&rsquo;re not eligible</h1>
                  <p>This in-context opportunity is taking place in participants’ homes or workplaces.</p>
                  <p>
                    Keep an eye out for{' '}
                    <Link to="/opportunities" className="link">
                      more opportunities
                    </Link>{' '}
                    as we have new ones coming up all the time.
                  </p>
                  <p>
                    <Link to={`${this.props.bookingBaseUrl}/apply`} className="link" style={{ color: 'inherit', opacity: 0.5 }}>
                      Go back
                    </Link>
                  </p>
                  {hideOpportunity}
                </Wrapper>
              </div>
            );
          }
          case 'message/feedback-sample': {
            ga.event({
              category: '(Booking) Feedback sample',
              action: 'View prompt',
            });
            // mixpanel.track({ event: 'View prompt (feedback sample)', payload: { step: 1, _booking_id: booking._id } });
            return (
              <div className="screenerPage">
                <Wrapper>
                  <div className="textCenter">
                    <img src="/assets/feedback-sample-screen-1.png" alt="" className="contentImage" height="249" />
                  </div>
                  <h1>Want to 3x your chances of getting selected?</h1>
                  <p>
                    Take 30 seconds to leave a voice message, and increase your chances of getting selected by <strong>300%</strong>
                  </p>
                  <Button
                    label="Sure"
                    labelColor="#fff"
                    bgColor="#FF5266"
                    className="btnNext mtop60"
                    type="button"
                    onClick={() => {
                      ga.event({
                        category: '(Booking) Feedback sample',
                        action: 'Click "Sure"',
                        label: 'Sure',
                      });
                      // mixpanel.track({ event: 'Click Next (feedback sample)', payload: { step: 1, _booking_id: booking._id } });
                      this.props.history.push(`${this.props.bookingBaseUrl}/apply/message/feedback-sample-primer`);
                    }}
                  />
                  <p className="mtop20 textCenter">
                    <Link
                      onClick={() => {
                        ga.event({
                          category: '(Booking) Feedback sample',
                          action: 'Click "No thanks"',
                          label: 'No thanks',
                        });
                        // mixpanel.track({ event: 'Click cancel (feedback sample)', payload: { step: 1, _booking_id: booking._id } });
                        this.closeScreener();
                      }}
                      to="#"
                      replace
                    >
                      No thanks
                    </Link>
                  </p>
                </Wrapper>
              </div>
            );
          }
          case 'message/feedback-sample-primer': {
            // mixpanel.track({ event: 'View prompt (feedback sample)', payload: { step: 2, _booking_id: booking._id } });
            return (
              <div className="screenerPage">
                <Wrapper>
                  <h1>Adding a voice message</h1>
                  <p>
                    Including a voice message with your application helps the researcher get a feel for your communication style, and helps your submission
                    stand out over everyone else!
                  </p>
                  <div className="textCenter">
                    <img src="/assets/feedback-sample-screen-2.png" alt="" className="contentImage" height="212" />
                  </div>
                  <p>To begin, follow the prompts on the next page.</p>
                  <Button
                    label="Next"
                    labelColor="#fff"
                    bgColor="#FF5266"
                    className="btnNext mtop60"
                    type="button"
                    onClick={() => {
                      ga.event({
                        category: '(Booking) Feedback sample',
                        action: 'Click "Next" on primer',
                        label: 'Next',
                      });
                      // mixpanel.track({ event: 'Click Next (feedback sample)', payload: { step: 2, _booking_id: booking._id } });
                      this.props.history.push(`${this.props.bookingBaseUrl}/apply/message/feedback-sample-instructions`);
                    }}
                  />
                </Wrapper>
              </div>
            );
          }
          case 'message/feedback-sample-instructions': {
            // mixpanel.track({ event: 'View prompt (feedback sample)', payload: { step: 3, _booking_id: booking._id } });
            const phoneNumber = _.get(this.state, 'user.contact.phone.mobile') && utils.parsePhoneNumber(this.state.user.contact.phone.mobile);

            const getFeedbackNumber = country => {
              switch (country) {
                case 'AU':
                  return { href: '+61731865743', label: '07 3186 5743' };
                case 'NZ':
                  return { href: '+6436694620', label: '03-669 4620' };
                case 'US':
                  return { href: '+18777853902', label: '(877) 785-3902' };
                case 'CA':
                  return { href: '+13653632561', label: '(365) 363-2561' };
                case 'GB':
                  return { href: '+448081642392', label: '0808 164 2392' };
                case 'PH':
                  return { href: '+63286263497', label: '(02) 8626 3497' };
                case 'IN':
                  return { href: '+918000402644', label: '080004 02644' };
                // REST OF EUROPE
                case 'AL':
                case 'AD':
                case 'AM':
                case 'AT':
                case 'BY':
                case 'BE':
                case 'BA':
                case 'BG':
                case 'CH':
                case 'CY':
                case 'CZ':
                case 'DE':
                case 'DK':
                case 'EE':
                case 'ES':
                case 'FO':
                case 'FI':
                case 'FR':
                case 'GE':
                case 'GI':
                case 'GR':
                case 'HU':
                case 'HR':
                case 'IE':
                case 'IS':
                case 'IT':
                case 'LT':
                case 'LU':
                case 'LV':
                case 'MC':
                case 'MK':
                case 'MT':
                case 'NO':
                case 'NL':
                case 'PO':
                case 'PT':
                case 'RO':
                case 'RU':
                case 'SE':
                case 'SI':
                case 'SK':
                case 'SM':
                case 'TR':
                case 'UA':
                case 'VA':
                  return { href: '+448081642392', label: '(+44) 808 164 2392' };
                default:
                  return { href: '+61731865743', label: '(+61) 7 3186 5743' };
              }
            };
            const feedbackNumber = getFeedbackNumber(_.get(this.state, 'user.location.country'));

            return (
              <div className="screenerPage">
                <Wrapper>
                  <h1>Step 1:</h1>
                  <p>
                    Make sure you are <strong>calling from</strong> the number you signed up to Askable with
                  </p>
                  {phoneNumber && (
                    <p className="mbottom40">
                      <strong style={{ color: '#FF5266' }}>{_.get(phoneNumber, 'format.national', phoneNumber.input)}</strong>
                    </p>
                  )}
                  <h1>Step 2:</h1>
                  <p>Make sure you’re in a quiet environment. Once you’re ready, call the number below and leave a voice message with the following:</p>
                  <p>
                    <strong>- Your full name name</strong>
                    <br />
                    (e.g. “Hi I’m Lisa Smith”)
                  </p>
                  <p>
                    <strong>- Tell us a little bit about yourself</strong>
                    <br />
                    (just a short 10 second intro is fine)
                  </p>
                  <p>That’s it, then just hang up!</p>
                  <div className="feedback-sample-phonenumber mtop10 mbottom40">
                    <img src="/assets/feedback-sample-phone-icon.svg" alt="" className="icon" />
                    <a href={`tel:${feedbackNumber.href}`}>{feedbackNumber.label}</a>
                  </div>
                  <h1>Step 3:</h1>
                  <p>After you’ve successfully left your voice message, just click the button to finish.</p>
                  <Button
                    label="Finish"
                    labelColor="#fff"
                    bgColor="#FF5266"
                    className="btnNext mtop20"
                    type="button"
                    onClick={() => {
                      ga.event({
                        category: '(Booking) Feedback sample',
                        action: 'Click "Finish" on instructions',
                        label: 'Finish',
                      });
                      // mixpanel.track({ event: 'Click Finish (feedback sample)', payload: { step: 3, _booking_id: booking._id } });
                      this.closeScreener();
                    }}
                  />
                </Wrapper>
              </div>
            );
          }
          case 'message/task-sent-email': {
            return (
              <div className="screenerPage">
                <Wrapper>
                  <h1>Task link sent</h1>
                  <p>Check your email - the link is on its way!</p>
                </Wrapper>
              </div>
            );
          }
          case 'message/remote-setup-check': {
            const onDesktop = (
              <Fragment>
                <p>We’ll be testing your:</p>
                <ul>
                  <li>Webcam</li>
                  <li>Microphone</li>
                  <li>Screen sharing</li>
                </ul>
                <Button
                  label="Begin"
                  labelColor="#fff"
                  bgColor="#FF5266"
                  type="button"
                  className="mtop20"
                  onClick={() => {
                    this.props.history.push('/live/setup-check/in-progress');
                  }}
                />
                <p className="mtop20 fontSmall textCenter">Takes just 1-2 minutes!</p>
              </Fragment>
            );

            const onMobile = (
              <Mutation
                mutation={gql`
                  mutation sendMyselfGenericEmail($email: GenericEmailInput!) {
                    sendMyselfGenericEmail(email: $email)
                  }
                `}
              >
                {(sendEmail, { loading, called }) => (
                  <Fragment>
                    <p className="fontSmall mtop30 mbottom20">To get started, click the button below and open the link on your laptop.</p>
                    <Button
                      label={called ? 'Link sent' : 'Email me the link'}
                      labelColor="#fff"
                      bgColor="#FF5266"
                      type="button"
                      className="mtop10"
                      disabled={loading || called}
                      onClick={() => {
                        // console.log(sendEmail, booking_id);
                        sendEmail({
                          variables: {
                            email: {
                              subject: 'Askable laptop setup link',
                              message_body:
                                'Here’s the link for you to complete the video chat setup check on your laptop.<br /><br >Make sure that you have <a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a> installed on your laptop.',
                              button_text: 'Go to laptop setup check',
                              button_path: '/live/setup-check',
                              button_link_auth: true,
                            },
                          },
                        });
                      }}
                    />
                    <p className="mtop20 fontSmall textCenter">
                      <Link to={this.props.bookingBaseUrl}>{called ? 'Close' : 'I’ll do this later'}</Link>
                    </p>
                  </Fragment>
                )}
              </Mutation>
            );

            return (
              <div className="screenerPage">
                <Wrapper>
                  <img
                    src="/assets/setup-check-header.png"
                    alt="Laptop setup check"
                    style={{
                      margin: '0 auto 20px',
                      display: 'block',
                    }}
                  />
                  <h2>Laptop setup check</h2>
                  <p>
                    To make sure there won’t be any technical issues on the day, you need to do a compulsory setup check.{' '}
                    <em>You only need to do this once.</em>
                  </p>
                  <UserAgentTest validation={ua => _.get(ua, 'device.type') === 'desktop'}>
                    {onDesktop}
                    {onMobile}
                    {onMobile}
                  </UserAgentTest>
                </Wrapper>
              </div>
            );
          }
          default:
            return (
              <div className="screenerPage">
                <Wrapper>
                  <h1>{this.props.match.params.message ? `Error - ${this.props.match.params.message}` : 'Unknown Error'}</h1>
                  <pre>{`${page}/${_.get(this.props, 'match.params.message', '')}`}</pre>
                  <p>
                    We&rsquo;ve encountered an error with your registration. Please{' '}
                    <a href="/help" className="link">
                      contact us for help
                    </a>
                    .
                  </p>
                </Wrapper>
              </div>
            );
        }
      }
    }
  }

  render() {
    if (this.state.loading || this.props.findBookingSubmission.loading || this.props.participantSessionsByBooking.loading) {
      return <LoadingOverlay />;
    }

    if (_.get(this.props, 'fetchUserById.userByID._id') && _.get(this.props, 'fetchBookingById.bookingByID._id')) {
      if (utils.getUserRestrictions(_.get(this.props, 'fetchUserById.userByID'))) {
        const restrictions = utils.getUserRestrictions(this.props.fetchUserById.userByID);
        let restricted = false;
        for (const key in restrictions) {
          switch (key) {
            case '_booking_id':
              restricted = restricted || restrictions._booking_id !== this.props.fetchBookingById.bookingByID._id;
              break;
            default:
          }
        }

        if (restricted) return <Redirect to="/" />;
      }
    }

    return (
      <div className="screenerPage">
        {this.popupContents()}
        <Modal
          open={!!this.state.error}
          title={this.state.error}
          actions={
            <FlatButton
              label="Close"
              primary
              onClick={() => {
                this.setState({ error: null });
                this.closeScreener();
              }}
            />
          }
        />
        <Header
          context="closeButton"
          onClick={() => {
            if (this.props.isPreview) return false;
            ga.event({
              category: 'Booking',
              action: 'Close Apply popup',
              label: 'Close button',
            });
            this.closeScreener();
          }}
        />
      </div>
    );
  }
}

const BookingSubmissionContainer = graphql(findBookingSubmission, {
  name: 'findBookingSubmission',
  options: props => ({
    variables: props.queryVariables.findBookingSubmission,
  }),
});
const UserDetailsContainer = graphql(fetchUserById, {
  skip: props => !props.isAuthenticated(),
  name: 'fetchUserById',
});
const ParticipantSessionsByBookingContainer = graphql(participantSessionsByBooking, {
  name: 'participantSessionsByBooking',
  options: props => ({
    variables: props.queryVariables.participantSessionsByBooking,
  }),
});

const updateMeContainer = graphql(updateMe, {
  props: ({ mutate }) => ({
    updateUser: user =>
      mutate({
        variables: { user },
        refetchQueries: [
          {
            query: fetchUserById,
            variables: { id: localStorage.get('connectedParticipant') },
          },
        ],
      }),
  }),
});
const createBookingSubmissionContainer = graphql(createBookingSubmission, {
  props: ({ mutate, ownProps }) => ({
    createBookingSubmission: (submission, turnstileToken, monocleBundle, metadata) =>
      mutate({
        variables: { submission, challenge: turnstileToken, vpn_info: monocleBundle, metadata },
        refetchQueries: [
          {
            query: findBookingSubmission,
            variables: ownProps.queryVariables.findBookingSubmission,
          },
        ],
      }),
  }),
});
const participantSaveAvailabilityContainer = graphql(participantSaveAvailability, {
  props: ({ mutate }) => ({
    participantSaveAvailability: (booking_id, session_ids) =>
      mutate({
        variables: { booking_id, session_ids },
      }),
  }),
});
const participantCancelSessionContainer = graphql(participantCancelSession, {
  props: ({ mutate }) => ({
    participantCancelSession: (participant_session_id, cancel_reason) =>
      mutate({
        variables: { participant_session_id, cancel_reason },
      }),
  }),
});

const logScreenerStartDraftContainer = graphql(
  gql`
    mutation logScreenerStartDraft($_booking_id: ID!) {
      logScreenerStartDraft(_booking_id: $_booking_id)
    }
  `,
  {
    props: ({ mutate }) => ({
      logScreenerStartDraft: variables => mutate({ variables }),
    }),
  },
);

export default withKinde(
  withMonocle(
    compose(
      BookingSubmissionContainer,
      UserDetailsContainer,
      ParticipantSessionsByBookingContainer,
      updateMeContainer,
      createBookingSubmissionContainer,
      participantSaveAvailabilityContainer,
      participantCancelSessionContainer,
      logScreenerStartDraftContainer,
    )(BookingApply),
  ),
);
