import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import { Link, Redirect } from 'react-router-dom';
import _ from 'lodash';

import { Button, Wrapper } from 'components/common';

import { localStorage } from 'lib/storage';

// Queries
// import fetchUserByEmail from 'data/queries/user/fetchUserByEmail';
import userByID from 'data/queries/user/fetchUserBasicInfoById';

// Mutations
import forgotPasswordMutation from 'data/mutations/user/forgotPassword';
import { withKinde } from '../../AuthWrapper'
// Styles
import './styles/loginStyles.scss';

class FirstTimePassword extends Component {
    constructor() {
        super();

        this.state = {
            user: {
                email: null,
                meta: {
                    identity: {
                        firstname: null
                    }
                },
                type: {}
            },
            // errorText: '',
            viewState: null,
            loading: false,
            redirectToLogin: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillReceiveProps(newProps) {
        // if (newProps.fetchUserByID.loading !== this.props.fetchUserByID.loading) {
        //     this.setState({ loading });
        // }
        if (!this.state.user.email && _.get(newProps, 'userByID.userByID')) {
            const user = Object.assign({}, this.state.user);
            let { viewState } = this.state;

            user.email = newProps.userByID.userByID.email || null;
            user.meta.identity.firstname = (newProps.userByID.userByID.meta && newProps.userByID.userByID.meta.identity && newProps.userByID.userByID.meta.identity.firstname) || null;
            user.type.participant = !!(newProps.userByID.userByID.type && newProps.userByID.userByID.type.participant);
            user.type.client = !!(newProps.userByID.userByID.type && newProps.userByID.userByID.type.client);

            if (!user.type.participant) {
                viewState = user.type.client ? 'user-is-client' : 'user-invalid';
            }

            this.setState({ user, viewState });
        }
    }

    handleSubmit() {
        this.setState({
            loading: true
        });

        localStorage.delete('queryUserId');

        // Validate if user exists
        this.props.forgotPassword(this.state.user.email, 'participant')
            .then(() => {
                this.setState({
                    loading: false,
                    // errorText: '',
                    // user: {
                    //     email: ''
                    // }
                    viewState: 'confirmation'
                });
                // this.snackbar.open('We have sent you a confirmation link by email.');
            });
    }

    render() {
        if (this.state.loading || _.get(this.props, 'userByID.loading')) {
            return <Wrapper loading />;
        }
        switch (this.state.viewState) {
            case 'confirmation':
                return (
                    <Wrapper>
                        <h1>Welcome to Askable!</h1>
                        <p className="mtop60">Great, we&apos;ve sent you an email to <u>{this.state.user.email}</u>. If the email doesn&apos;t arrive soon, double-check your spam folder or contact us on 3040 4719.</p>
                    </Wrapper>
                );
            case 'user-invalid':
                return (
                    <Wrapper>
                        <p className="mtop60">It looks like there&apos;s a problem with your account - please <Link className="link" to="/help">contact us</Link>.</p>
                    </Wrapper>
                );
            case 'user-is-client':
                return (
                    <Wrapper>
                        <p className="mtop60">It looks like you&apos;re already signed up to Askable as a client. Maybe you&apos;d like to log in to <a href="https://app.askable.com" className="link">your dashboard</a>, otherwise <Link className="link" to="/help">visit the help centre</Link>.</p>
                    </Wrapper>
                );
            default:
                return (
                    <Wrapper
                        className="forgotPasswordExternalContainer"
                        // containerClassName="forgotPasswordContainer"
                        // leftContainerSize={{ maxWidth: 600, paddingBottom: 100 }}
                    >
                        <h1>Set your password to continue</h1>
                        <p className="mtop60">Hi {this.state.user.meta.identity.firstname}, welcome to the new Askable app!</p>
                        <p>You already have an Askable account from being involved in previous opportunities, but to continue logging in, you&apos;ll need to set a new password.</p>
                        <p>By clicking the button below, we&apos;ll send an email to <u>{this.state.user.email}</u> for you to set your password. When you get it, just click the link and follow the instructions.</p>
                        <div className="buttonsContainer">
                            <Button
                                label="Send Password Link"
                                labelColor="#fff"
                                bgColor="#FF5266"
                                onClick={this.handleSubmit}
                                className="btnNext"
                            />
                        </div>
                        {this.state.redirectToLogin && (
                            <Redirect to="/login" />
                        )}
                        <p className="mtop60 textCenter">Already know your password? <Link to="/login" className="link">Log in</Link></p>
                    </Wrapper>
                );
        }
    }
}

// const fetchUserByEmailQuery = graphql(fetchUserByEmail, {
//     options: email => ({
//         variables: {
//             email,
//         },
//     })
// });
const fetchUserByID = graphql(userByID, {
    name: 'userByID',
    options: () => ({
        variables: { id: localStorage.get('queryUserId') },
    }),
});

const forgotPasswordMutationContainer = graphql(forgotPasswordMutation, {
    props: ({ mutate }) => ({
        forgotPassword: (email, type) => mutate({
            variables: { email, type, urlQuery: localStorage.get('tagetPath') ? `?loginDestination=${localStorage.get('tagetPath')}` : undefined }
        })
    })
});

export default withKinde(compose(
    // fetchUserByEmailQuery,
    fetchUserByID,
    forgotPasswordMutationContainer
)(FirstTimePassword));
