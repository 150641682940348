import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
// import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import moment from 'lib/moment';
import _ from 'lodash';
import dot from 'dot-object';

import { utils } from 'lib/utils';
import { ga } from 'lib/analytics';

import { fetchBookingByIdAdditional } from 'data/queries/booking/fetchBookingById';
import participantCancelWaitlistedSession from 'data/mutations/participantCancelWaitlistedSession';

// import FlatButton from 'material-ui/FlatButton';

import AccessTimeIcon from 'material-ui/svg-icons/device/access-time';
import PersonIcon from 'material-ui/svg-icons/social/person';
import GroupIcon from 'material-ui/svg-icons/social/group';
import HeadsetIcon from 'material-ui/svg-icons/hardware/headset-mic';
import MoneyIcon from 'material-ui/svg-icons/editor/attach-money';
import GiftCardIcon from 'material-ui/svg-icons/action/card-giftcard';

import { LoadingOverlay, List, Dialog } from 'components/common';
import { Card, CardChild } from 'components/common/Card/view';

// import ConfirmAttendance from './confirmAttendanceView';

// Styles
import './styles/booking.scss';

class BookingWaitlistView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      booking: props.booking,
      bookingAdditional: false,
      validWaitlist: [],
      loading: false,
    };

    // utils.fbPixelTrack('ViewContent', {
    //   content_ids: props.booking._id,
    //   content_type: 'Booking - registered',
    // });
    // this.reloadBooking = this.reloadBooking.bind(this);
    this.setBookingState = this.setBookingState.bind(this);
  }

  componentWillMount() {
    this.setBookingState(this.props);
  }

  componentWillReceiveProps(newProps) {
    this.setBookingState(newProps);
  }

  setBookingState(props) {
    if (!this.state.bookingAdditional && props.fetchBookingByIdAdditional.bookingByID && !props.fetchBookingByIdAdditional.loading && this.state.booking) {
      const flatBooking = Object.assign({}, dot.dot(this.state.booking), dot.dot(props.fetchBookingByIdAdditional.bookingByID));
      this.setState({
        // booking: dot.object(Object.assign({}, dot.dot(this.state.booking), dot.dot(props.fetchBookingByIdAdditional.bookingByID))),
        booking: flatBooking ? dot.object(flatBooking) : {},
        bookingAdditional: true,
      });
    }

    if (JSON.stringify(props.waitlist) !== JSON.stringify(this.state.waitlist)) {
      this.setState({
        validWaitlist: props.waitlist.filter(
          booking_participant => booking_participant.status === 2 && booking_participant.cancel === 0 && moment().isBefore(booking_participant.session.start),
        ),
      });
    }
  }

  render() {
    if (this.state.loading || !this.state.bookingAdditional) {
      return <LoadingOverlay />;
    }

    const summaryListItems = [];

    if (this.props.sessionDuration) {
      summaryListItems.push({
        key: 'sessionLength',
        primaryText: `${this.props.sessionDuration} session`,
        leftIcon: <AccessTimeIcon />,
      });
    }
    switch (this.props.booking.type) {
      case 1:
        if (this.props.booking.config.session) {
          switch (this.props.booking.config.session.type) {
            case 1:
              summaryListItems.push({
                key: 'sessionType',
                primaryText: 'Casual, one on one chat',
                leftIcon: <PersonIcon />,
              });
              break;
            case 2:
              summaryListItems.push({
                key: 'sessionType-alt',
                primaryText: 'Small focus group',
                leftIcon: <GroupIcon />,
              });
              break;
            default:
          }
        }
        break;
      case 2:
        summaryListItems.push({
          key: 'sessionType',
          primaryText: 'Remote / Video chat',
          leftIcon: <HeadsetIcon />,
        });
        break;
      default:
    }

    if (this.props.booking.config.incentive) {
      switch (this.props.booking.config.incentive.type) {
        case 1:
          summaryListItems.push({
            key: 'paymentMethod',
            primaryText: 'Paid via PayPal or E-Gift Card',
            leftIcon: <MoneyIcon />,
          });
          break;
        case 2:
          summaryListItems.push({
            key: 'paymentMethod-alt',
            primaryText: 'Paid via gift card / voucher',
            leftIcon: <GiftCardIcon />,
          });
          break;
        default:
      }
    }

    const clientInfo = {};
    switch (this.props.booking.config.session.type) {
      case 1:
        clientInfo.sessionType = '1 on 1 chat';
        break;
      case 2:
        clientInfo.sessionType = 'focus group';
        break;
      default:
        clientInfo.sessionType = null;
    }

    clientInfo.location = null;
    if (this.props.booking.config.location) {
      if (this.props.booking.config.location.city) {
        clientInfo.location = this.props.booking.config.location.city;
      } else if (this.props.booking.config.location.state) {
        clientInfo.location = this.props.booking.config.location.state;
      }
      if (this.props.booking.config.location.state && clientInfo.location > '' && this.props.booking.config.location.state !== clientInfo.location) {
        clientInfo.location += `, ${this.props.booking.config.location.state}`;
      }
    }

    if (_.get(this.props.booking, 'config.in_context.location_type')) {
      if (this.props.inContextLocation) {
        switch (this.props.booking.config.in_context.location_type) {
          case 1:
            clientInfo.location = `Your home in ${this.props.inContextLocation.city}`;
            break;
          case 2:
            clientInfo.location = `Your workplace/business in ${this.props.inContextLocation.city}`;
            break;
          default:
            clientInfo.location = `Your chosen location at ${this.props.inContextLocation.city}`;
        }
      } else {
        clientInfo.location = null;
      }
    }

    return (
      <div className="bookingPage bookingOverview bookingRegistered waitlist">
        {clientInfo.name || clientInfo.sessionType ? (
          <h1 className="pageTitle">
            You’re on the wait list for:
            <br />
            <small>
              {clientInfo.name}
              {clientInfo.name && clientInfo.sessionType ? ' - ' : ''}
              {clientInfo.sessionType}
            </small>
            {clientInfo.location ? (
              <small>
                <br />
                {clientInfo.location}
              </small>
            ) : null}
          </h1>
        ) : (
          <h1 className="pageTitle">
            You’re on the wait list for this session:
            {clientInfo.location ? (
              <small>
                <br />
                {clientInfo.location}
              </small>
            ) : null}
          </h1>
        )}
        <div className="subtle-warning-box mleft-20 mright-20 mbottom20">
          <div className="mleft20 mright20 mtop10">
            <h3>Please note:</h3>
            <p>
              You have been placed on the wait list and are not currently confirmed to attend this session. If there are any cancellations, we&rsquo;ll be in
              touch to see if you&rsquo;re still available.
            </p>
          </div>
        </div>
        {this.props.booking.config.information && this.props.booking.config.information.brief_summary ? (
          <div>
            <h3>Brief summary</h3>
            <ReactMarkdown className="md-block">{this.props.booking.config.information.brief_summary}</ReactMarkdown>
          </div>
        ) : null}
        <div className="waitlist-summary mtop60">
          <Card>
            <CardChild type="text">
              <span className="incentive">
                <strong>
                  {dot.pick('config.incentive.currency_symbol', this.props.booking) || ''}
                  {dot.pick('config.incentive.value', this.props.booking) || ''}
                </strong>{' '}
                incentive
              </span>
            </CardChild>
            <CardChild type="divider" />
            <CardChild type="text" className="card-cols wrap">
              <List
                className="summary-list"
                itemDefaultProps={{
                  disabled: true,
                  className: 'list-item',
                }}
                items={summaryListItems}
              />
            </CardChild>
          </Card>
        </div>
        <h2 className="mtop40">Sessions you’re waitlisted for:</h2>
        <ul className="session-list">
          {this.state.validWaitlist.map(booking_participant => (
            <li key={booking_participant._id}>
              <span>{moment(booking_participant.session.start).format('dddd Do MMM')}</span>
              <strong>{moment(booking_participant.session.start).formatZoneFallback('h:mm A')}</strong>
              <button
                className="link cancelButton"
                onClick={() => {
                  if (this.props.confirmAttendanceAnonymous) {
                    utils.window.location.href = `/login?loginDestination=/booking/${this.props.booking._id}`;
                    return;
                  }
                  ga.event({ category: 'Booking', action: 'Click Change waitlist attendance', label: booking_participant._id });
                  this.setState({ unwaitlist: booking_participant });
                }}
              >
                Cancel
              </button>
            </li>
          ))}
        </ul>

        <Dialog
          customActions={[
            {
              label: 'Cancel session',
              primary: true,
              onClick: () => {
                this.setState({ loading: true });
                this.props
                  .participantCancelWaitlistedSession(this.state.unwaitlist._id)
                  .then(() => {
                    utils.window.location.reload();
                  })
                  .catch(err => {
                    console.error(err);
                  });
              },
            },
          ]}
          title="Cancel waitlist availability"
          open={!!this.state.unwaitlist}
          modal={false}
          onCancel={() => {
            this.setState({ unwaitlist: null });
          }}
          // onConfirm={() => { this.setState({ unwaitlist: null }); }}
        >
          <p>
            Are you sure you want to leave the waitlisted session for{' '}
            <strong>{this.state.unwaitlist && moment(this.state.unwaitlist.session.start).formatZoneFallback('dddd do MMM [at] h:mm A')}</strong>?
          </p>
          <p>You may not be able to rejoin this session.</p>
        </Dialog>
      </div>
    );
  }
}

const BookingDetailsContainer = graphql(fetchBookingByIdAdditional, {
  name: 'fetchBookingByIdAdditional',
  options: props => ({
    variables: { id: props.booking._id },
  }),
});
const participantCancelWaitlistedSessionContainer = graphql(participantCancelWaitlistedSession, {
  props: ({ mutate }) => ({
    participantCancelWaitlistedSession: participant_session_id =>
      mutate({
        variables: { participant_session_id },
      }),
  }),
});

export default compose(BookingDetailsContainer, participantCancelWaitlistedSessionContainer)(BookingWaitlistView);
