import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import ReCAPTCHA from 'react-google-recaptcha';
import Spinner from 'react-spinkit';
import _ from 'lodash';

import { utils } from 'lib/utils';

import verifyCaptcha from 'data/mutations/verifyCaptcha';

import './styles/RecaptchaStyles.scss';

class recaptchaCommon extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            verified: false
        };

        this.verifyCaptcha = this.verifyCaptcha.bind(this);

        utils.expose('captcha', this.verifyCaptcha);
    }

    verifyCaptcha(response) {
        return new Promise((resolve, reject) => {
            this.setState({ loading: true });
            this.props.verifyCaptcha(response)
                .then((success) => {
                    this.props.onVerify(success);
                    this.setState({
                        loading: false,
                        verified: success
                    });
                    resolve({ success: true });
                })
                .catch((error) => {
                    console.error(error);
                    this.props.onVerify(false);
                    this.setState({
                        loading: false,
                        verified: false
                    });
                    resolve({ success: false, error });
                });
        });
    }

    render() {
        if (this.state.verified) {
            return null;
        }
        if (this.state.loading) {
            return (
                <div className={this.props.className}>
                    <Spinner fadeIn="none" name="three-bounce" color="#FF5266" />
                </div>
            );
        }
        const classNames = this.props.className ? this.props.className.split(' ') : [];
        if (this.props.flex) {
            classNames.push('recaptcha-flex');
        }
        return (
            <ReCAPTCHA
                sitekey="6Ld8R3QUAAAAAFfUh-4Oa8JhFSHG0XvQZpUFIpJo"
                // size="compact"
                {..._.pick(this.props, [
                    'sitekey',
                    'theme',
                    'type',
                    'tabindex',
                    'onExpired',
                    'onErrored',
                    'stoken',
                    'size',
                    'badge',
                ])}
                className={classNames.join(' ')}
                onChange={this.verifyCaptcha}
            />
        );
    }
}

const verifyCaptchaMutation = graphql(verifyCaptcha, {
    props: ({ mutate }) => ({
        verifyCaptcha: captchaResponse => mutate({
            variables: { captchaResponse }
        })
    }),
});

export default compose(verifyCaptchaMutation)(recaptchaCommon);
