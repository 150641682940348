import React, { Component } from 'react';
import uuid from 'uuid/v4';
import _ from 'lodash';
import { graphql, compose } from 'react-apollo';

import { localStorage } from 'lib/storage';

import { LoadingOverlay } from 'components/common';

import registerParticipant from 'data/mutations/registerParticipant';


class TempAutoRegister extends Component {
    componentDidMount() {
        const email = `${uuid()}@temp.askable.com`;
        const password = uuid();

        console.log({ email, password });

        this.props.registerParticipant(
            email, password, null,
            localStorage.get('utm_source') || undefined,
            localStorage.get('utm_campaign') || undefined,
            localStorage.get('signup_settings') ? JSON.parse(localStorage.get('signup_settings')) : undefined,
        )
            .then(async ({ data }) => {
                const user = _.get(data, 'registerParticipant');
                if (!user) {
                    window.location.href = '/signup/email';
                    return;
                }

                localStorage.save('connectedParticipant', user._id);
                localStorage.save('participant_access_token', user.access_token);
        localStorage.save(
          'participant_details',
          JSON.stringify({
                    email: user.email,
                    name: {
                        firstname: _.get(user, 'meta.identity.firstname'),
              lastname: _.get(user, 'meta.identity.lastname'),
            },
          }),
        );

                localStorage.delete('signup_settings');

                // await mixpanel.track({ event: 'Login', _user_id: user._id });
                // await mixpanel.profile(user._id, { $last_login: new Date() });

                window.location.href = '/onboard';
            })
            .catch(() => {
                window.location.href = '/signup/email';
            });
    }

    render() {
        return <LoadingOverlay />;
    }
}

const registerParticipantMutation = graphql(registerParticipant, {
    props: ({ mutate }) => ({
        registerParticipant: (email, password, google_id, source, campaign, settings) => mutate({
            variables: {
                email, password, google_id, source, campaign, settings
            }
        }),
    }),
});

export default compose(registerParticipantMutation)(TempAutoRegister);
