import React from 'react';
import _ from 'lodash';
import moment from 'lib/moment';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import { utils } from 'lib/utils';
import globals from 'settings/globals';

import DateRangeIcon from 'material-ui/svg-icons/action/date-range';
import AccessTimeIcon from 'material-ui/svg-icons/device/access-time';
import PersonIcon from 'material-ui/svg-icons/social/person';
import GroupIcon from 'material-ui/svg-icons/social/group';
import HeadsetIcon from 'material-ui/svg-icons/hardware/headset-mic';
import MoneyIcon from 'material-ui/svg-icons/editor/attach-money';
import GiftCardIcon from 'material-ui/svg-icons/action/card-giftcard';
import WorldIcon from 'material-ui/svg-icons/social/public';
import AssignmentIcon from 'material-ui/svg-icons/action/assignment';
import PlaceIcon from 'material-ui/svg-icons/maps/place';
import AvLibraryBooksIcon from 'material-ui/svg-icons/av/library-books';
import AiModeratedIcon from '/assets/ai-moderated-icon.svg';

import ListComponent from '../common/List/view';
import { StaticMap } from '../common';

export default function (props) {
  const sessions = _.chain(props.booking.session)
    .filter(session => session.status !== 2 && session.status !== 3)
    .orderBy(['start', 'end'], ['asc', 'asc'])
    .value();

  const summaryListItems = [];

  switch (props.booking.type) {
    case 3:
      if (sessions && sessions[0]) {
        summaryListItems.push({
          key: 'dateRange',
          primaryText: `Closes ${moment(sessions[0].end).formatZoneFallback('Do MMM, h:mma')}`,
          leftIcon: <DateRangeIcon />,
        });
      }
      if (props.sessionDuration) {
        summaryListItems.push({
          key: 'sessionLength',
          primaryText: `${props.sessionDuration}`,
          leftIcon: <AccessTimeIcon />,
        });
      }
      break;
    case 4:
      {
        // DIARY STUDY
        if (sessions && sessions[0]) {
          summaryListItems.push({
            key: 'dateRange',
            primaryText: `Ends ${moment(sessions[0].end).formatZoneFallback('Do MMM, h:mma')}`,
            leftIcon: <DateRangeIcon />,
          });
        }

        const durationString = utils.diaryStudyDuration(props.booking || {});

        if (durationString) {
          summaryListItems.push({
            key: 'sessionLength',
            primaryText: durationString,
            leftIcon: <AccessTimeIcon />,
          });
        }
      }
      break;
    default:
      if (sessions && sessions[0]) {
        summaryListItems.push({
          key: 'dateRange',
          primaryText:
            moment(sessions[0].start).format('dddd Do MMM') === moment(sessions[sessions.length - 1].end).format('dddd Do MMM')
              ? moment(sessions[0].start).formatZoneFallback('dddd Do MMM')
              : `${moment(sessions[0].start).formatZoneFallback('dddd Do MMM')} - ${moment(sessions[sessions.length - 1].end).formatZoneFallback(
                  'dddd Do MMM',
                )}`,
          leftIcon: <DateRangeIcon />,
        });
      }
      if (props.sessionDuration) {
        summaryListItems.push({
          key: 'sessionLength',
          primaryText: `${props.sessionDuration} session`,
          leftIcon: <AccessTimeIcon />,
        });
      }
  }
  switch (props.booking.type) {
    case 1:
      if (props.booking.config.session) {
        switch (props.booking.config.session.type) {
          case 1:
            summaryListItems.push({
              key: 'sessionType',
              primaryText: 'Casual, one on one chat',
              leftIcon: <PersonIcon />,
            });
            break;
          case 2:
            summaryListItems.push({
              key: 'sessionType-alt',
              primaryText: 'Small focus group',
              leftIcon: <GroupIcon />,
            });
            break;
          default:
        }
      }
      switch (_.get(props.booking, 'config.in_context.location_type')) {
        case 1:
          summaryListItems.push({
            key: 'incontextLocationType',
            primaryText: 'In-home visit',
            leftIcon: <PlaceIcon />,
          });
          break;
        case 2:
          summaryListItems.push({
            key: 'incontextLocationType',
            primaryText: 'Your workplace',
            leftIcon: <PlaceIcon />,
          });
          break;
        case 3:
          summaryListItems.push({
            key: 'incontextLocationType',
            primaryText: 'Public location of your choice (e.g. Cafe)',
            leftIcon: <PlaceIcon />,
          });
          break;
        default:
      }
      break;
    case 2:
      summaryListItems.push({
        key: 'sessionType',
        primaryText: 'Remote / Video chat',
        leftIcon: <HeadsetIcon />,
      });
      break;
    case 3:
      switch (_.get(props.booking, 'config.online_task.type')) {
        case 3:
          summaryListItems.push({
            key: 'sessionType-alt',
            primaryText: 'Self-managed video call',
            leftIcon: <img style={{ margin: 0, top: 'auto', padding: 2 }} src={AiModeratedIcon} />,
          });
          break;
        case 2:
          summaryListItems.push({
            key: 'sessionType-alt',
            primaryText: 'Survey',
            leftIcon: <AssignmentIcon />,
          });
          break;
        default:
          summaryListItems.push({
            key: 'sessionType-alt',
            primaryText: 'Online task',
            leftIcon: <WorldIcon />,
          });
      }
      break;
    case 4:
      summaryListItems.push({
        key: 'sessionType',
        primaryText: 'Multi-stage task', // DIARY STUDY
        leftIcon: <AvLibraryBooksIcon />,
      });
      break;
    default:
  }

  if (props.booking.config.incentive) {
    switch (props.booking.config.incentive.type) {
      case 1:
        summaryListItems.push({
          key: 'paymentMethod',
          primaryText: 'You’ll be paid via PayPal or E-Gift Card',
          leftIcon: <MoneyIcon />,
        });
        break;
      case 2:
        summaryListItems.push({
          key: 'paymentMethod-alt',
          primaryText: 'You’ll be paid via gift card / voucher',
          leftIcon: <GiftCardIcon />,
        });
        break;
      default:
    }
  }

  const screenerQuestions = _.filter(props.booking.config.question, question => question.config.display_participant);
  let eligibilitySummary = [];

  if (props.booking.config.criteria) {
    eligibilitySummary = utils.getEligibilitySummary(props.booking.config.criteria);
  }

  _.forEach(screenerQuestions, question => {
    const screenInOptions = _.filter(question.options, option => option.screen_in).map(option => {
      return {
        key: `option_${option._id}`,
        description: option.label,
      };
    });
    if (screenInOptions.length === 0) {
      return null;
    }
    eligibilitySummary.push({
      key: `question_${question._id}`,
      description: question.title,
      children: screenInOptions,
    });
  });

  let location = null;
  if (props.booking.config.location) {
    if (props.booking.config.location.city) {
      location = props.booking.config.location.city;
    } else if (props.booking.config.location.state) {
      location = props.booking.config.location.state;
    }
    if (props.booking.config.location.state && location > '' && props.booking.config.location.state !== location) {
      location += `, ${props.booking.config.location.state}`;
    }

    if (_.get(props.booking, 'config.in_context.location_type')) {
      if (props.booking.config.location.region) {
        location = `${props.booking.config.location.region}, ${props.booking.config.location.state}`;
      } else {
        location = props.booking.config.location.state;
      }
    }
  }

  let defaultIncentiveValue = '0';
  if (props.isPreview) {
    defaultIncentiveValue = globals.incentive_value_preview_default;
    if (props.booking.type === 3) {
      defaultIncentiveValue = globals.incentive_value_preview_default_quant;
    }
  }

  let locationInfo = null;
  if (props.booking.type === 1) {
    const locationItems = [];
    if (_.get(props.booking, 'config.location.region')) {
      locationItems.push(props.booking.config.location.region);
    } else if (_.get(props.booking, 'config.location.city')) {
      locationItems.push(props.booking.config.location.city);
    } else if (_.get(props.booking, 'config.location.state')) {
      locationItems.push(props.booking.config.location.state);
    }

    let locationMap = null;
    if (_.get(props.booking, 'config.in_context.location_type')) {
      let locationType = null;
      switch (props.booking.config.in_context.location_type) {
        case 1:
          locationType = 'participants’ homes';
          break;
        case 2:
          locationType = 'participants’ businesses / workplaces';
          break;
        case 3:
          locationType = 'a neutral location (e.g. Cafe, Library) of your choice';
          break;
        default:
      }

      locationInfo = (
        <div>
          <h3>Location</h3>
          <p>
            The sessions will be held in {locationType} in the{' '}
            {_.get(props.booking, 'config.location.region') || _.get(props.booking, 'config.location.city') || 'researcher’s'} area
          </p>
        </div>
      );
    } else {
      if (_.get(props.booking, 'config.location.latitude') && _.get(props.booking, 'config.location.longitude')) {
        locationMap = (
          <div className="mtop30 mbottom20">
            <StaticMap
              latitude={props.booking.config.location.latitude}
              longitude={props.booking.config.location.longitude}
              height={300}
              maxWidth={580}
              openMap
              tracking={{
                _booking_id: props.booking._id,
                'Booking page type': 'Overview',
              }}
            />
          </div>
        );
      }

      locationInfo = (
        <div>
          <h3>Location</h3>
          <p>The sessions will be held in {_.uniq(locationItems).join(', ')}</p>
          {locationMap}
          <p>Exact location information is provided if you get selected to attend.</p>
        </div>
      );
    }
  }

  return (
    <div className="bookingPage bookingOverview">
      <div className="banner">
        <h2 className="incentive mbottom10">
          <span>Get</span>
          <span>
            <span>{props.booking.config.incentive.currency_symbol || ''}</span>
            {props.booking.config.incentive.value || defaultIncentiveValue}
          </span>
        </h2>

        {_.get(props.booking, 'config.information.short_description') && (
          <h3 className="incentive mbottom10">{props.booking.config.information.short_description}</h3>
        )}
        {location ? <h3 className="location">{location}</h3> : null}
        <hr className="mtop20" />
        <ListComponent
          className="summary-list"
          itemDefaultProps={{
            disabled: true,
            className: 'list-item',
          }}
          items={summaryListItems}
        />
      </div>
      {eligibilitySummary.length > 0 ? (
        <div>
          <h3>Eligibility</h3>
          <ul className="eligibility-summary liPadx2">
            {eligibilitySummary.map(item => (
              <li key={item.key}>
                {item.description}
                {item.children ? (
                  <ul>
                    {item.children.map(child => (
                      <li key={child.key}>{child.description}</li>
                    ))}
                  </ul>
                ) : null}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      {props.booking.config.information && props.booking.config.information.brief_summary && props.booking.config.information.brief_summary > '' ? (
        <div>
          <h3>Brief summary</h3>
          <ReactMarkdown className="md-block">{props.booking.config.information.brief_summary}</ReactMarkdown>
          {props.booking.type === 3 && _.get(props.booking, 'config.online_task.type') === 3 && (
            <Link to="/" style={{ opacity: 0.5 }}>
              Learn more
            </Link>
          )}
        </div>
      ) : null}
      {locationInfo}
      {props.isPreview ? null : (
        <p className="mtop60">
          <Link
            to="/opportunities"
            className="link"
            style={{ color: 'inherit', opacity: 0.5 }}
            onClick={() => {
              utils.hideOpportunity(props.booking._id);
            }}
          >
            Hide this opportunity
          </Link>
        </p>
      )}
    </div>
  );
}
