import gql from 'graphql-tag';

export default gql`
    query FetchParticipantEmailByID($id:ID) {
        userByID(id: $id) {
            email
            meta {
                identity {
                    firstname
                }
            }
            type {
                participant
                client
            }
        }
    }
`;
