import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Toolbar, ToolbarGroup, FlatButton, IconButton, LinearProgress } from 'material-ui';

// import _ from 'lodash';

import NavigationMenu from 'material-ui/svg-icons/navigation/menu';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
import NavigationArrowBack from 'material-ui/svg-icons/navigation/arrow-back';
import { PayoutMethodWarningBanner } from 'components/common/PayoutMethodWarningBanner/PayoutMethodWarningBanner';
import { HELP_URL } from '../../../lib/data/constants';

// Styles
// import './styles/HeaderStyles.scss';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let headerOutput = null;

    switch (this.props.context) {
      case 'drawer':
        headerOutput = (
          <Toolbar
            className="toolbar drawer"
            style={{
              backgroundColor: 'rgb(255,255,255)',
            }}
          >
            <ToolbarGroup firstChild>
              {this.props.drawerPage > 1 ? (
                <FlatButton
                  label="Back"
                  onClick={this.props.menuClick}
                  style={{
                    minWidth: 'none',
                  }}
                  icon={<NavigationArrowBack />}
                />
              ) : null}
            </ToolbarGroup>
            <ToolbarGroup lastChild>
              {this.props.drawerPage === 1 ? (
                <IconButton onClick={this.props.menuClick}>
                  <NavigationClose />
                </IconButton>
              ) : null}
            </ToolbarGroup>
          </Toolbar>
        );
        break;
      case 'closeButton':
      case 'closeButton light':
        headerOutput = (
          <div style={this.props.style} className="closeButton">
            <IconButton onClick={this.props.onClick}>
              <NavigationClose
                {...(this.props.context === 'closeButton light'
                  ? {
                      color: '#e2e2e2',
                      hoverColor: '#ffffff',
                    }
                  : {})}
              />
            </IconButton>
          </div>
        );
        break;
      case 'messagesConversation':
        headerOutput = (
          <Toolbar
            className="toolbar"
            style={{
              backgroundColor: 'rgb(255,255,255)',
            }}
          >
            <ToolbarGroup firstChild style={{ flexBasis: '165px' }}>
              <FlatButton
                label="Inbox"
                onClick={() => {
                  this.props.history.push('/messages');
                }}
                style={{
                  minWidth: 'none',
                }}
                icon={<NavigationArrowBack />}
              />
            </ToolbarGroup>
            {this.props._booking_id && (
              <ToolbarGroup lastChild>
                <FlatButton
                  label="Opportunity Details"
                  className="opportunityDetails"
                  onClick={() => {
                    this.props.history.push(`/booking/${this.props._booking_id}`);
                  }}
                  style={{
                    minWidth: 'none',
                  }}
                />
              </ToolbarGroup>
            )}
          </Toolbar>
        );
        break;
      default:
        headerOutput = (
          <div className="defaultHeader">
            <PayoutMethodWarningBanner />
            <Toolbar
              style={{
                backgroundColor: 'rgb(255,255,255)',
              }}
              className="toolbar"
            >
              <ToolbarGroup>
                <Link to={this.props.context === 'previewHeader' ? `${this.props.location.pathname}${this.props.location.search}` : '/'}>
                  <img src="/askable-logo.svg" alt="Askable" style={{ marginTop: '6%' }} />
                </Link>
                <FlatButton
                  label="Help"
                  onClick={() => {
                    if (this.props.context !== 'previewHeader') window.location.href = HELP_URL;
                  }}
                  style={{
                    minWidth: 'none',
                  }}
                  labelStyle={{
                    // paddingLeft: 0,
                    // paddingRight: 0,
                    textTransform: 'none',
                  }}
                />
              </ToolbarGroup>
              <ToolbarGroup lastChild>
                <IconButton onClick={this.props.menuClick}>{this.props.notifications ? <NavigationMenu /> : <NavigationMenu />}</IconButton>
              </ToolbarGroup>
            </Toolbar>
            <LinearProgress
              mode="determinate"
              min={0}
              max={100}
              value={Math.max(0, Math.min(100, Math.round(parseFloat(this.props.progress || 0) * 100)))}
              className={`progressBar ${this.props.progress !== null && !Number.isNaN(this.props.progress) ? 'active' : 'hidden'}`}
              style={{
                borderRadius: 0,
                backgroundColor: '#F5F5F5',
                position: 'absolute',
              }}
            />
          </div>
        );
    }

    return headerOutput;
  }
}

export default withRouter(Header);
// export default Header;
