import gql from 'graphql-tag';

export default gql`
    mutation changePasswordByRequestIDMutation($email: String!, $request_id: String!, $new_password: String!) {
        changePasswordByRequestID(email: $email, request_id: $request_id, new_password: $new_password) {
            _id
            email
        }
    }
`;
