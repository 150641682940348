import gql from 'graphql-tag';

export default gql`
    mutation registerParticipant($email: String!, $password: String, $google_id: String, $source: String, $campaign: String, $settings: SettingsInput) {
        registerParticipant(email: $email, password: $password, google_id: $google_id, source: $source, campaign: $campaign, settings: $settings ) {
            _id
            email
            access_token
        }
    }
`;
