import React, { Component } from 'react';
import gql from 'graphql-tag';
import _ from 'lodash';
import { Query, Subscription } from 'react-apollo';

import { utils } from 'lib/utils';

const OnlineUsersFragment = gql`
    fragment OnlineUsersFragment on AskableLiveOnlineUser {
        _user_id updated online
        # User { _id type { client participant } }
    }
`;

const OnlineUsersQuery = gql`
    query askableLiveOnlineStatus ($_booking_id: ID!, $_session_id: ID!) {
        askableLiveOnlineStatus (booking_id: $_booking_id, session_id: $_session_id, online: true) {
            ...OnlineUsersFragment
        }
    }
    ${OnlineUsersFragment}
`;
const OnlineUsersSubscription = gql`
    subscription askableLiveOnlineStatus ($_booking_id: ID!, $_session_id: ID!) {
        askableLiveOnlineStatus (booking_id: $_booking_id, session_id: $_session_id, online: true) {
            ...OnlineUsersFragment
        }
    }
    ${OnlineUsersFragment}
`;

class Listener extends Component {
    componentWillMount() {
        this.unsubscribe = this.props.subscribe();
    }
    componentWillUnmount() {
        if (this.unsubscribe) this.unsubscribe();
    }

    render() {
        return null;
    }
}

export default ({
    _booking_id,
    _session_id,
    onUpdate,
    setOnline,
    ...props
}) => (
    <Query
        query={OnlineUsersQuery}
        variables={{ _booking_id, _session_id }}
        pollInterval={props.pollInterval}
        onCompleted={(data) => {
            const users = _.get(data, 'askableLiveOnlineStatus') || [];
            const userIsOnline = !!_.find(users, ({ online, _user_id }) => (online && _user_id === utils.getUserId()))
            if (onUpdate) {
                onUpdate(_.filter(users, ({ _user_id }) => _user_id !== utils.getUserId()));
            }
            if (!userIsOnline && setOnline) {
                setOnline(true).catch(() => {
                    window.location.reload();
                })
            }
        }}
    >
    {({ subscribeToMore, loading }) => {
        if (loading) return null;
        return (
            <Listener
                subscribe={() => {
                    subscribeToMore({
                        document: OnlineUsersSubscription,
                        variables: { _booking_id, _session_id },
                        updateQuery: (prev, { subscriptionData }) => {
                            if (subscriptionData && subscriptionData.data) return subscriptionData.data;
                        }
                    });
                }}
            />
        );
    }}
    </Query>
);
