import { datadogRum } from '@datadog/browser-rum';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { useEffect, useState } from 'react';

export function IdentifyUser() {
  const { user, isAuthenticated } = useKindeAuth();
  const [hasIdentified, setHasIdentified] = useState(false);

  const identifyUser = () => {
    if (import.meta.env.VITE_ENVIRONMENT !== 'production' || !isAuthenticated || hasIdentified) {
      return;
    }

    datadogRum.setUser({
      id: user.id,
    });

    setHasIdentified(true);
  };

  useEffect(() => {
    identifyUser();
  }, [user]);

  return null;
}
