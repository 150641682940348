import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import dotObject from 'dot-object';
import _ from 'lodash';

import { ga } from 'lib/analytics';

import Dialog from 'components/common/Dialog/view';

// import updateMe from 'data/mutations/updateMe';
// import createBookingSubmission from 'data/mutations/createBookingSubmission';

import { Wrapper, LoadingOverlay, RecordingIndicator, Header } from 'components/common';

import BookingScreenerQuestionView from './bookingScreenerQuestionView';
import BookingScreenerSummaryView from './bookingScreenerSummaryView';

import UserDetailsForm from '../../userGetDetails/formContentsView';
import { getCanvasID, getWebglID } from 'get-browser-fingerprint';
// import { threadId } from 'worker_threads';

dotObject.override = true;

const SUBMIT_ERRORS = {
  block_unverified_paypal: 'submit-unverified-paypal',
  block_giftpay: 'submit-giftpay-not-allowed',
  other: 'other'
}

class BookingScreenerView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageIndex: 0,
      submissionAttempts: [],
      user: {},
      profileValues: {

      },
      screenerValues: {

      },
      prefilledQuestions: [],
      loading: props.pageCount === 0,
      endPreviewDialogOpen: false,
      runthrough: 0,
      respondedQuestions: {},
      submitError: null,
    };

    this.onQuestionSubmit = this.onQuestionSubmit.bind(this);
    this.onQuestionChange = this.onQuestionChange.bind(this);
    this.onMediumThreeSubmit = this.onMediumThreeSubmit.bind(this);
    this.onMediumThreeChange = this.onMediumThreeChange.bind(this);
    this.getQuestionIndex = this.getQuestionIndex.bind(this);
    this.setScreenerPage = this.setScreenerPage.bind(this);
    // this.getPageIndex = this.getPageIndex.bind(this);
    this.submitScreener = this.submitScreener.bind(this);
    this.handleSubmitScreenerClick = this.handleSubmitScreenerClick.bind(this);
  }

  componentWillMount() {
    const results = this.processPreviousResponses(this.props);
    this.setState(results);
    // no questions left to show so submit the answers
    if (this.props.pageCount === 0 && !this.props.isPreview) {
      // there were screener questions but they had already been answered previously
      if (results.prefilledQuestions.length > 0) {
        this.submitScreener(_.concat(..._.values(results.screenerValues)));
      } else {
        // no screener questions on the booking
        this.submitScreener([]);
      }
    }
  }

  // componentWillReceiveProps(newProps) {
  //   if (newProps.page !== this.props.page) {
  //     const pageIndex = this.getPageIndex(newProps.page);
  //     this.setState({
  //       pageIndex,
  //       loading: false
  //     });
  //     // if (newProps.page > pageIndex) {
  //       // this.props.history.replace(`${this.props.bookingBaseUrl}/apply/screener/summary`);
  //       // if (this.props.isPreview) {
  //       //   this.props.history.replace(`${this.props.bookingBaseUrl}/apply/screener/${newProps.page - 1}`);
  //       //   this.setState({ endPreviewDialogOpen: true });
  //       // } else {
  //       //   this.submitScreener(_.concat(..._.values(this.state.screenerValues)));
  //       // }
  //     // }
  //   }
  // }

  onQuestionSubmit(event, question) {
    if (event) {
      event.preventDefault();
    }
    if (question.index !== this.state.pageIndex) {
      console.warn('Submitting question from a different page');
      return;
    }
    // const question._id = _.get(this.props.booking, `config.question.${this.state.pageIndex}._id`);
    if (question._id) {
      if (!_.find(_.get(this.state.screenerValues, question._id, []), _question => _question._answer_id || _question.value.match(/[^\s]/))) {
        console.warn('Current question has no answer value');
        return;
      }
    }

    // mixpanel.track({ event: 'Answer screener question', payload: { _booking_id: this.props.booking._id } });
    ga.event({
      category: 'Booking',
      action: 'Answer screener question',
      label: this.props.booking._id,
      value: (this.state.pageIndex + 1) / this.props.pageCount
    });
    // this.props.history.push(`${this.props.bookingBaseUrl}/apply/screener/${this.state.pageIndex + 1}`);
    this.setScreenerPage(this.state.pageIndex + 1);
  }

  onQuestionChange(question, value) {
    const screenerValues = Object.assign({}, this.state.screenerValues);
    screenerValues[question._id] = [...this.getScreenerValues(question, value, false)];
    const respondedQuestions = Object.assign({}, this.state.respondedQuestions);
    if (value.length > 0) {
      respondedQuestions[question._id] = true;
    }
    this.setState({ screenerValues, respondedQuestions });
  }

  onMediumThreeSubmit(event, userProp) {
      // refreshStateFromProps={this.refreshStateFromProps}
    if (event) {
      event.preventDefault();
    }
    if (!_.get(this.state.profileValues[userProp], 'valid')) {
        return false;
    }
    switch (userProp) {
        // case 'meta.work.status':
        //     this.props.updateUser(this.state.user, () => {
        //         if (
        //             _.get(this.state.user, 'meta.work.status.fulltime')
        //             || _.get(this.state.user, 'meta.work.status.parttime')
        //         ) {
        //             window.location.reload();
        //         }
        //     });
        // break;
        default:
            this.props.updateUser(this.state.user);
    }
    // mixpanel.track({ event: 'Submit medium 3', payload: { user_field: userProp, _booking_id: this.props.booking._id } });
    ga.event({
      category: 'Booking',
      action: 'Answer screener question',
      label: this.props.booking._id,
      value: (this.state.pageIndex + 1) / this.props.pageCount
    });
    this.setScreenerPage(this.state.pageIndex + 1);
    // this.props.history.push(`${this.props.bookingBaseUrl}/apply/screener/${this.state.pageIndex + 1}`);
  }

  onMediumThreeChange(prop, value, valid, error) {
    const profileValues = Object.assign({}, this.state.profileValues),
      user = Object.assign({}, this.state.user);

    profileValues[prop] = { value, valid, error };
    switch (prop) {
      case 'meta.work._industry_id':
      case 'meta.work.employment_type':
        dotObject.str('meta.work', value, user);
      break;
      default:
        dotObject.str(prop, value, user);
    }
    this.setState({ user, profileValues });
  }

  getQuestionIndex(_question_id) {
    return _.findIndex(this.props.booking.config.question, ['_id', _question_id]);
  }

  setScreenerPage(page, options = {}) {
    this.setState({ pageIndex: Math.max(0, Math.min(this.props.pageCount, page)) });
  }

  // subset of booking.config.question containing only the prefilled questions
  getPrefilledQuestions() {
    return this.props.booking.config.question.filter(q => q.previous_responses && q.previous_responses.length > 0);
  }

  // subset of booking.config.question without the prefilled questions
  getCurrentQuestions() {
    return this.props.booking.config.question.filter(q => !q.previous_responses || q.previous_responses.length === 0);
  }

  getScreenerValues(question, value, isPrefilled) {
    const result = [];
    switch (typeof value) {
      case 'object':
        if (value.length) {
          _.forEach(value, (_answer_id) => {
            result.push({
              _question_id: question._id,
              _answer_id,
              prefilled_response: isPrefilled,
            });
          });
        }
        break;
      case 'string':
      case 'number':
        result.push({
          _question_id: question._id,
          value,
          prefilled_response: isPrefilled
        });
        break;
      default:
    }
    return result;
  }

  // getPageIndex(urlPage) {
  //   return Math.max(0, Math.min(this.props.pageCount, urlPage));
  // }

  getEndPreviewDialog() {
    return (
      <Dialog
        open={this.state.endPreviewDialogOpen}
        title="That&apos;s the last screener question"
        onCancel={() => this.setState({ endPreviewDialogOpen: false })}
        onConfirm={() => {
          this.setState({
            endPreviewDialogOpen: false,
            screenerValues: {},
            runthrough: this.state.runthrough + 1
          });
          // this.props.history.push(`${this.props.bookingBaseUrl}/apply/screener`);
          this.setScreenerPage(0);
        }}
      >
        <p>Would you like to reset your preview?</p>
      </Dialog>
    );
  }

  getMediumThreeQuestions(index) {
    let childIndex = index;
    return (this.props.mediumThree || []).map((userProp) => {
      let activePage = false;
      const classNames = ['userGetDetails screener'];
      if (childIndex < this.state.pageIndex) {
        classNames.push('didCollect');
      } else if (childIndex > this.state.pageIndex) {
        classNames.push('willCollect');
      } else {
        classNames.push('activePage');
        activePage = true;
      }

      childIndex += 1;
      const wrapperKey = `wrapper_user_${userProp}`;
      return (
        <Wrapper
          containerClassNames={classNames}
          key={wrapperKey}
        >
          <form
            onSubmit={event => this.onMediumThreeSubmit(event, userProp)}
            className="wholePageQuestion"
          >
            <input
              type="submit"
              value="Submit"
              style={{
                position: 'absolute',
                left: '-9999px',
                tabIndex: -1
              }}
            />
            <UserDetailsForm
              userProp={userProp}
              // userPropValues={userPropValues}
              valueChangeCallback={this.onMediumThreeChange}
              activePage={activePage}
              submissionAttempt={this.state.submissionAttempts[userProp]}
            />
          </form>
        </Wrapper>
      );
    });
  }

  checkFormProgress(bookingSubmissionData, currentPage = null) {
    if (!_.get(this.props.booking, 'config.question')) {
      return { progress: 0, length: 0 };
    }
    const currentQuestions = this.getCurrentQuestions();
    const checkUntil = currentPage || currentQuestions.length;
    for (let i = 0; i < checkUntil; i += 1) {
      if (!_.find(bookingSubmissionData, response => response._question_id === currentQuestions[i]._id)) {
        return { progress: i, length: checkUntil };
      }
    }
    return { progress: checkUntil, length: checkUntil };
  }

  prefillMultipleChoiceAnswers(question, screenerValues, answeredQuestionIds) {
    // get options that match previous responses
    const options = question.options.filter(opt => question.previous_responses.findIndex(item => item.toLowerCase() === opt.label.toLowerCase()) !== -1);
    options.forEach((opt) => {
      const optionId = opt._id.valueOf().toString();
      // make sure the answers haven't already been added for this question
      const exists = (screenerValues[question._id] || []).filter(v => v._answer_id === optionId).length > 0;
      if (!exists) {
        // add that screener value for that option to the state
        if (!screenerValues[question._id]) {
          screenerValues[question._id] = [];
        }
        answeredQuestionIds.add(question._id);
        const result = this.getScreenerValues(question, [optionId], true);
        screenerValues[question._id].push(...result);
      }
    });
  }

  prefillShortAnswer(question, screenerValues, answeredQuestionIds) {
    if (!screenerValues[question._id]) {
      screenerValues[question._id] = [];
    }
    if (question.previous_responses && question.previous_responses.length > 0) {
      answeredQuestionIds.add(question._id);
      const result = this.getScreenerValues(question, question.previous_responses[0], true);
      screenerValues[question._id].push(...result);
    }
  }

  processPreviousResponses(props) {
    const screenerValues = Object.assign({}, this.state.screenerValues);

    const answeredQuestionIds = new Set();

    // get the questions that have previous responses
    const prefilled = (props.booking.config.question || [])
                        .filter(q => q.previous_responses && q.previous_responses.length > 0);
    prefilled.forEach((question) => {
      // multiple choice questions
      if (question.config.type === 1) {
        this.prefillMultipleChoiceAnswers(question, screenerValues, answeredQuestionIds);
      } else if (question.config.type === 2) { // short answer
        this.prefillShortAnswer(question, screenerValues, answeredQuestionIds);
      }
    });

    return {
      screenerValues,
      prefilledQuestions: [...answeredQuestionIds]
    };
  }

  submitScreener(values, turnstileToken) {
    if (this.props.isPreview) {
      return;
    }
    const bookingSubmissionData = (values && values.length && values.length === _.filter(values, '_question_id').length)
      ? values
      : _.concat(..._.values(this.state.screenerValues));

    const formProgress = this.checkFormProgress(bookingSubmissionData);
    if (formProgress.progress < formProgress.length) {
      console.warn('Looks like there are missing values');
      // this.props.history.replace(`${this.props.bookingBaseUrl}/apply/screener/${formProgress.progress}`);
      this.setScreenerPage(formProgress.progress, { replace: true });
      return;
    }
    // const bookingSubmissionData = _.concat(..._.values(this.state.screenerValues));
    if (this.state && !this.state.loading) {
      this.setState({ loading: true });
    }

    const saveSubmission = {
      _user_id: _.get(this.props, 'fetchUserById.userByID._id'),
      _booking_id: this.props.booking._id,
      data: bookingSubmissionData,
      in_context: this.props.in_context,
    };

    if (_.get(this.props.booking, 'config.participant_agreement.type') === 1) {
      saveSubmission.agreement = { consent: this.props.agreementConsent };
    }
    this.props.getMonocleBundle().then(
      (monocleBundle) => {
        const canvas_id = getCanvasID()?.toString() ?? 'failed';
        const webgl_id = getWebglID()?.toString() ?? 'failed';
        const metadata = {
          browser_data: {
            canvas_id,
            webgl_id
          }
        };

        return this.props.createBookingSubmission(saveSubmission, turnstileToken, monocleBundle, metadata);
      }
    ).then((data) => {
      if (this.props.createBookingSubmissionThen) {
        this.props.createBookingSubmissionThen(data);
      }
    })
      .catch((error) => {
        console.error(error);
        const gqlMessage = _.get(error, 'graphQLErrors[0].message');
        console.error({ gqlMessage });
        if (/block_unverified_paypal/.test(gqlMessage)) {
          this.setState({ submitError: SUBMIT_ERRORS.block_unverified_paypal });
        } else if (/block_giftpay/.test(gqlMessage)) {
          this.setState({ submitError: SUBMIT_ERRORS.block_giftpay });
        } else {
          this.setState({ submitError: SUBMIT_ERRORS.other });
        }
      });
  }

  handleSubmitScreenerClick() {
    if(!window.turnstile || !import.meta.env.VITE_TURNSTILE_SITE_KEY) {
      console.error('Turnstile not loaded or site key not set');
      return this.submitScreener(null, null);
    }

    window.turnstile.render('#tstl-container', {
      sitekey: import.meta.env.VITE_TURNSTILE_SITE_KEY,
      action: 'study_submission',
      callback: (token) => {
        this.submitScreener(null, token);
      },
    });
  }

  render() {
    if (this.state.submitError) {
      return <Redirect to={`${this.props.bookingBaseUrl}/apply/error/${this.state.submitError}`} />;
    }

    if (this.state.loading || this.props.findBookingSubmission.loading) {
      return <LoadingOverlay />;
    }

    if (this.props.findBookingSubmission.findBookingSubmission && this.props.findBookingSubmission.findBookingSubmission[0]) {
      const url = `${this.props.bookingBaseUrl}/apply/availability`;
      return <Redirect to={url} />;
    }

    let currentAnswers = [];
    if (this.state) {
      currentAnswers = Object.keys(this.state.profileValues || {}).concat(Object.keys(this.state.screenerValues || {}));
    }
    if (this.state.pageIndex > currentAnswers.length) {
      const url = `${this.props.bookingBaseUrl}/apply/screener/${currentAnswers.length}`;
      return <Redirect to={url} />;
    }

    let childIndex = 0;
    const endPreviewDialog = this.props.isPreview ? this.getEndPreviewDialog() : null;

    const mediumThreeQuestions = this.getMediumThreeQuestions(childIndex);
    childIndex += mediumThreeQuestions.length;

    const pages = [
      ...mediumThreeQuestions,
      ...(this.props.booking.config.question || []).filter(q => this.state.prefilledQuestions.indexOf(q._id) === -1)
      .map((question) => {
        const classNames = ['userGetDetails screener'];
        if (childIndex < this.state.pageIndex) {
          classNames.push('didCollect');
        } else if (childIndex > this.state.pageIndex) {
          classNames.push('willCollect');
        } else {
          classNames.push('activePage');
          // activePage = true;
        }

        const _question = Object.assign({ index: childIndex }, question);
        childIndex += 1;
        const wrapperKey = `wrapper_question_${question._id}`;
        const screenerKey = `screenerQuestions_${this.state.runthrough}`;
        return (
          <Wrapper
            containerClassNames={classNames}
            key={wrapperKey}
          >
            <BookingScreenerQuestionView
              key={screenerKey}
              question={question}
              onSubmit={event => this.onQuestionSubmit(event, _question)}
              onValueChange={this.onQuestionChange}
            />
          </Wrapper>
        );
      }),
      (
        <Wrapper
          className={`userGetDetails screener ${this.state.pageIndex < childIndex ? 'willCollect' : ''}`}
        >
          <BookingScreenerSummaryView
            values={this.state.screenerValues}
            submitScreener={this.handleSubmitScreenerClick}
            {..._.pick(this.props, [
              'booking',
              'bookingBaseUrl',
              'history',
              'isPreview',
            ])}
          />
        </Wrapper>
      )
    ];

    return (
      <div className="screenerQuestionWrapper">
        <RecordingIndicator />
        <div className="questions">
          {pages[this.state.pageIndex - 1]}
          {pages[this.state.pageIndex]}
          {pages[this.state.pageIndex + 1]}
        </div>
        <Header
          context="closeButton light"
          onClick={() => {
            if (this.props.isPreview) return;
            this.props.history.push(this.props.bookingBaseUrl);
          }}
        />
        {endPreviewDialog}
      </div>
    );
  }
}


export default BookingScreenerView
