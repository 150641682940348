import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import { Input, Button, Wrapper } from 'components/common';

// Queries
import fetchUserByEmail from 'data/queries/user/fetchUserByEmail';

// Mutations
import forgotPasswordMutation from 'data/mutations/user/forgotPassword';

// Styles
import './styles/loginStyles.scss';

class ForgotPassword extends Component {
    constructor() {
        super();

        this.state = {
            user: {
                email: '',
                meta: {
                    identity: {
                        firstname: ''
                    }
                }
            },
            errorText: '',
            loading: false,
            redirectToLogin: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit() {
        this.setState({
            loading: true
        });

        // Validate if user exists
        this.props.forgotPassword(this.state.user.email, 'participant')
        .then(() => {
            this.setState({
                loading: false,
                errorText: '',
                user: {
                    email: ''
                },
                viewStage: 'email-sent'
            });
        })
        .catch((e) => {
            this.setState({
                errorText: `Error: ${e}`,
                loading: false
            });
        });
    }

    render() {
        switch (this.state.viewStage) {
            case 'email-sent':
                return (
                    <Wrapper
                        className="forgotPasswordExternalContainer"
                        containerClassName="forgotPasswordContainer"
                        leftContainerSize={{ maxWidth: 600, paddingBottom: 100 }}
                        loading={this.state.loading}
                    >
                        <div className="textCenter">
                            <img src="/askable-logo.svg" alt="Askable" width="200" className="mbottom40" />
                            <h1>Success!</h1>
                            <p className="mtop60">Your reset password email is on its way!</p>
                        </div>
                    </Wrapper>
                );
            default:
                return (
                    <Wrapper
                        className="forgotPasswordExternalContainer"
                        containerClassName="forgotPasswordContainer"
                        leftContainerSize={{ maxWidth: 600, paddingBottom: 100 }}
                        loading={this.state.loading}
                    >
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                if (this.state.user.email) {
                                    this.handleSubmit();
                                }
                            }}
                        >
                            <div className="textCenter">
                                <img src="/askable-logo.svg" alt="Askable" width="200" className="mbottom40" />
                                <h1>Forgot Password</h1>
                                <p className="mtop60">Please enter your email address to search for your account</p>
                            </div>
                            <Input
                                name="email"
                                placeholder="Email"
                                type="email"
                                errorText={this.state.errorText}
                                value={this.state.user.email}
                                required
                                onChange={(value) => {
                                    this.setState({
                                        user: {
                                            ...this.state.user,
                                            email: value
                                        }
                                    });
                                }}
                            />
                            <div className="buttonsContainer">
                                <Button
                                    label="Send Reset Link"
                                    labelColor="#fff"
                                    bgColor="#FF5266"
                                    className="btnNext"
                                    disabled={!this.state.user.email}
                                    type="submit"
                                />
                            </div>
                            {this.state.redirectToLogin && (
                                <Redirect to="/login" />
                            )}
                        </form>
                    </Wrapper>
                );
        }
    }
}

const forgotPasswordMutationContainer = graphql(forgotPasswordMutation, {
    props: ({ mutate }) => ({
        forgotPassword: (email, type) => mutate({
            variables: { email, type }
        })
    })
});

export default compose(forgotPasswordMutationContainer)(ForgotPassword);
