import gql from 'graphql-tag';

export default gql`
    query FetchPasswordChangeRequestByRequestID($request_id:String!) {
        passwordChangeRequestByRequestID(request_id: $request_id) {
            _id
            request_id
            email
            user_id
            expired
        }
    }
`;
