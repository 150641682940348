import React, { Component, Fragment } from 'react';
import TwilioVideo from 'twilio-video';
import _ from 'lodash';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import { utils } from 'lib/utils';

import { Link, Redirect } from 'react-router-dom';
import Sound from 'react-sound';

import { Dialog } from 'components/common';

import Overlay from './askable-live-overlay-portal.view';
import MediaTrack from './askable-live-media-track.view';
import SettingsButton from './askable-live-settings-button.view';
import { HELP_URL } from '../../lib/data/constants';

class ChatRoom extends Component {
    constructor() {
        super();
        this.state = {
            room: {},
            local_video: null,
            local_audio: null,
            local_screen: null,
            errors: {},
            newResearcherAlertStatus: null,
        };

        this.setStateAsync = this.setStateAsync.bind(this);
        this.pushError = this.pushError.bind(this);
        this.roomInit = this.roomInit.bind(this);
        this.saveRoomAuth = this.saveRoomAuth.bind(this);
        this.localTracksInit = this.localTracksInit.bind(this);
        // this.attachResearcherTracks = this.attachResearcherTracks.bind(this);
        // this.attachResearcherTrack = this.attachResearcherTrack.bind(this);
        // this.removeResearcherTracks = this.removeResearcherTracks.bind(this);
        this.updateRemoteParticipants = this.updateRemoteParticipants.bind(this);
        this.updateParticipantTracks = this.updateParticipantTracks.bind(this);

        this.createLocalTrack = this.createLocalTrack.bind(this);
        // this.createLocalTrackDesktop = this.createLocalTrackDesktop.bind(this);
        this.republishLocalTrack = this.republishLocalTrack.bind(this);

        this.postLaunchCheckResearcherTracks = this.postLaunchCheckResearcherTracks.bind(this);

        this.disconnectParticipant = this.disconnectParticipant.bind(this);
        this.disableAllLocalTracks = this.disableAllLocalTracks.bind(this);
        this.disableLocalTrack = this.disableLocalTrack.bind(this);

        this.newResearcherAlert = this.newResearcherAlert.bind(this);

        this.renderRemoteTracks = this.renderRemoteTracks.bind(this);
        this.renderErrorMessages = this.renderErrorMessages.bind(this);
        this.renderErrorDialog = this.renderErrorDialog.bind(this);
        this.renderScreenShareStatus = this.renderScreenShareStatus.bind(this);
    }

    componentDidMount() {
        this.roomInit();
        // window.roomInit = this.roomInit;

        // TODO: review & remove exposed methods
        // window._ = _;
        // window.disconnectParticipant = this.disconnectParticipant;
        // window.disableAllLocalTracks = this.disableAllLocalTracks;
        // // window.createLocalTrackDesktop = this.createLocalTrackDesktop;
        // window.createLocalTrack = this.createLocalTrack;

        window.addEventListener('beforeunload', this.disconnectParticipant);
        window.addEventListener('resize', this.onWindowResize);
        this.onWindowResize();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.disconnectionMessage && (this.props.disconnectionMessage !== prevProps.disconnectionMessage) && _.get(this.state, 'room.instance')) {
            this.pushError('room_disconnected', new Error(this.props.disconnectionMessage))
                .then(() => {
                    this.disconnectParticipant();
                });
        }
        if (!this.props.disconnectionMessage && prevProps.disconnectionMessage && _.get(this.state, 'errors.room_disconnected')) {
            this.pushError('room_disconnected', null)
                .then(() => {
                    this.roomInit();
                });
        }

        if (prevState.researchers && (_.chain(this.state).get('researchers').keys().value().length > _.chain(prevState).get('researchers').keys().value().length)) {
            this.newResearcherAlert();
        }
    }

    componentWillUnmount() {
        this.disconnectParticipant();

        if (this.roomConnectTimeout) {
            clearTimeout(this.roomConnectTimeout);
        }

        window.removeEventListener('resize', this.onWindowResize);
    }

    onWindowResize() {
        const participantTrack = document.querySelector('.room-tracks > .video-track.participant');
        if (participantTrack) {
            participantTrack.classList.add('resized');
            const mediaWidth = `${Math.ceil(participantTrack.clientHeight * 1.4)}px`;
            participantTrack.querySelectorAll('.media').forEach((media) => {
                media.style.width = mediaWidth;
            });
        }
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    setElementContents(selector, child) {
        const parent = document.querySelector(selector);
        if (!parent) {
            console.warn(`setElementContents: no parent found for selector '${selector}'`);
            return;
        }
        parent.appendChild(child);
    }
    unsetElementContents(selector) {
        const parent = document.querySelector(selector);
        if (!parent) {
            console.warn(`unsetElementContents: no parent found for selector '${selector}'`);
            return;
        }
        parent.innerHTML = '';
    }

    pushError(key, e) {
        return new Promise((resolve) => {
            this.setState((state) => {
                state.errors[key] = e;
                return state;
            }, resolve);
        });
    }

    async roomInit() {
        if (this.state.errors) {
            await this.setStateAsync({ errors: {} });
        }
        // const initStateUpdates = await Promise.all([
        //     this.localTracksInit(),
        //     this.roomAuth()
        // ]);

        // const initState = _.merge({}, this.state, ...initStateUpdates);
        // await this.setStateAsync(initState);

        const initState = _.merge({}, this.state, await this.localTracksInit());

        // if (_.get(this.props, 'room.token')) {
        //     initState.room = {
        //         token: this.props.room.token,
        //         details: this.props.room.room
        //     };
        // }

        if (!_.isEmpty(this.state.errors)) {
            return;
        }
        if (this.props.error) {
            return;
        }

        if (!initState.local_screen) {
            initState.local_screen = await this.createLocalTrack('screen');
        }

        if (!this.props.readyToConnect) {
            const retry = 2500;
            this.roomConnectTimeout = setTimeout(this.roomInit, retry);
            return;
        }


        if (!_.get(initState, 'room.details.uniqueName') || (_.get(initState, 'room.updated', Date.now()) > Date.now() - (1000 * 60 * 3))) {
            const roomSetupResult = await this.props.roomSetup()
                .catch(this.props.onSetupError || ((error) => {
                    console.log(`Room setup failed: ${error.message}`);
                }));

            let resultRoom = _.get(roomSetupResult, 'data.askableLiveRoomSetup');
            if (!resultRoom) {
                resultRoom = _.get(roomSetupResult, 'data.askableLiveDemoRoomSetup');
            }

            initState.room = {
                token: _.get(resultRoom, 'token'),
                details: _.get(resultRoom, 'room'),
                updated: Date.now()
            };

            if (!_.get(initState, 'room.details.uniqueName')) {
                this.roomConnectTimeout = setTimeout(this.roomInit, 1000);
                return;
            }
        }

        initState.room.instance = await TwilioVideo.connect(initState.room.token, {
            name: initState.room.details.uniqueName,
            region: 'gll', // Global low latency
            tracks: _.filter([initState.local_audio, initState.local_video, initState.local_screen]),
            dominantSpeaker: true
        });

        await this.setStateAsync(initState);

        // console.log('connected', initState.room.instance);

        // initState.room.instance.on('participantConnected', () => {
        //     // console.log('Participant connected', Participant, initState.room);
        //     this.updateRemoteParticipants();
        // });
        // initState.room.instance.on('participantDisconnected', () => {
        //     // console.log('Participant disconnected', Participant, initState.room);
        //     this.updateRemoteParticipants();
        // });
        const listenEvents = [
            // 'disconnected',
            'participantConnected',
            'participantDisconnected',
            // 'reconnected',
            // 'reconnecting',
            // 'recordingStarted',
            // 'recordingStopped',
            'trackDimensionsChanged',
            'trackDisabled',
            'trackEnabled',
            'trackMessage',
            'trackPublished',
            // 'trackPublishPriorityChanged',
            'trackStarted',
            'trackSubscribed',
            'trackSwitchedOff',
            'trackSwitchedOn',
            'trackUnpublished',
            'trackUnsubscribed',
        ];
        for (const event of listenEvents) {
            // TODO: maybe debounce updateRemoteParticipants
            initState.room.instance.on(event, eventArgs => this.updateRemoteParticipants(event, eventArgs));
        }

        initState.room.instance.on('dominantSpeakerChanged', async (Participant) => {
            if (!Participant) {
                await this.updateRemoteParticipants();
                return;
            }
            const dominantTracks = [];
            Participant.videoTracks.forEach((Track) => {
                // if (Track.kind === 'video') {
                if (Track.trackSid) {
                    dominantTracks.push(Track.trackSid);
                }
            });
            await this.setStateAsync({ dominant_user: { Participant, _user_id: Participant.identity } });
            this.updateRemoteParticipants();
        });

        initState.room.instance.on('disconnected', (room, error) => {
            if (error) {
                console.error(error);
            }
            if (!_.get(this.state, 'errors.room_disconnected') && error) {
                this.pushError('room_disconnected', error);
            }
            // console.log('room instance disconnected', {
            //     roomStatus: room.status,
            //     error: error.message
            // });
        });

        this.updateRemoteParticipants();

        this.postLaunchCheckResearcherTracksTimeout = setTimeout(this.postLaunchCheckResearcherTracks, 5000);
    }

    saveRoomAuth({ token, room }) {
        this.setState({
            room: {
                token,
                details: room
            }
        });
    }

    async localTracksInit() {
        const saveTracks = {};

        if (!this.state.local_video || !this.state.local_audio) {
            const LocalTracks = await TwilioVideo.createLocalTracks({
                audio: { name: 'participant_audio', echoCancellation: true },
                video: { name: 'participant_video', echoCancellation: true }
            })
                .catch((e) => {
                    console.error('Error setting up video & audio tracks', e);
                    this.pushError('local_tracks', e);
                    return false;
                });

            if (LocalTracks) {
                saveTracks.local_video = _.find(LocalTracks, ['kind', 'video']);
                saveTracks.local_audio = _.find(LocalTracks, ['kind', 'audio']);
                this.setState(saveTracks);
            } else {
                return saveTracks;
            }
        }

        this.setState(saveTracks);

        return saveTracks;
    }

    async createLocalTrack(type, deviceId) {
        const stateKey = `local_${type}`;

        let track = null;
        switch (type) {
            case 'screen': {
                await this.setStateAsync({ desktopRequestPrompt: true, screenShareRequested: Date.now() });

                const ScreenShare = await window.navigator.mediaDevices.getDisplayMedia()
                    .catch((e) => {
                        console.error('Error setting up screen sharing', e);
                        this.pushError('local_screen', e);
                        return false;
                    });

                await this.setStateAsync({ desktopRequestPrompt: null });
                if (!ScreenShare) return;

                track = new TwilioVideo.LocalVideoTrack(
                    ScreenShare.getTracks()[0],
                    { name: 'participant_desktop' }
                );

                track.on('disabled', () => { this.disableLocalTrack(track, 'local_screen'); });
                track.on('stopped', () => { this.disableLocalTrack(track, 'local_screen'); });
            }
            break;
            case 'video':
                track = await TwilioVideo.createLocalVideoTrack({ name: 'participant_video', deviceId });
                this.setState({ local_audio: track });
            break;
            case 'audio':
                track = await TwilioVideo.createLocalAudioTrack({ name: 'participant_audio', deviceId, echoCancellation: true });
            break;
            default:
                return;
        }

        if (this.state.room.instance && track) {
            await utils.keepTrying(() => this.republishLocalTrack(track), 500, 5)
                .catch((e) => {
                    console.error(e);
                    this.roomInit();
                });
        }

        const state = {};
        state[stateKey] = track;

        await this.setStateAsync(state);
        return track;
    }

    republishLocalTrack(track) {
        // let publishedTrack = null;
        _.get(this.state, 'room.instance.localParticipant.tracks', []).forEach((entry) => {
            if (entry.track.name === track.name) {
                // publishedTrack = entry.track;
                this.state.room.instance.localParticipant.unpublishTrack(entry.track);
            }
        });

        // console.log('existing track', { publishedTrack, track });

        return this.state.room.instance.localParticipant.publishTrack(track);
    }

    updateRemoteParticipants() {
        // console.log('updateRemoteParticipants', this.state.room.instance);
        // console.log('updateRemoteParticipants', ...stuff);
        if (!_.get(this.state, 'room.instance.participants.forEach')) return;
        this.remote_tracks = {};
        this.researchers = {};
        this.observers = {};
        this.state.room.instance.participants.forEach(this.updateParticipantTracks);
        // console.log(_.pick(this, ['researchers', 'observers']));
        this.setState({
            remote_tracks: this.remote_tracks,
            researchers: this.researchers,
            observers: this.observers,
            remote_tracks_count: this.remote_tracks ? Object.values(this.remote_tracks).length : 0
        });
    }

    updateParticipantTracks(participant) {
        if (!participant) return;
        if (participant.tracks) {
            if (_.get(participant, 'identity')) {
                if (participant.identity.match(/^[0-9a-fA-F]{24}$/)) {
                    this.researchers[participant.identity] = participant;
                } else {
                    this.observers[participant.identity] = participant;
                }
            }
            participant.tracks.forEach((publication) => {
                if (publication.track && publication.isSubscribed) {
                    const { track } = publication;
                    if (track.isEnabled && track.isStarted) {
                        this.remote_tracks[track.sid] = track;
                    }
                }
            });
        }
    }

    postLaunchCheckResearcherTracks() {
        // console.log({
        //     'state.room.instance.participants': _.get(this.state, 'room.instance.participants'),
        //     'state.remote_audio': _.get(this.state, 'remote_audio'),
        //     'state.remote_video': _.get(this.state, 'remote_video'),
        // });
        // if (this.state.room.instance.participants && !(this.state.remote_audio && this.state.remote_video)) {
        //     this.state.room.instance.participants.forEach(this.attachResearcherTracks);
        // }
        if (!this.state.local_screen) {
            this.setState({ local_screen: false });
        }
        this.updateRemoteParticipants();
    }

    disconnectParticipant() {
        this.disableAllLocalTracks();

        if (this.state.room.instance) {
            console.log(this.state.room.instance);
            this.state.room.instance.disconnect();
        }
    }

    disableAllLocalTracks() {
        if (this.state.local_audio) {
            this.disableLocalTrack(this.state.local_audio, 'local_audio');
        }
        if (this.state.local_video) {
            this.disableLocalTrack(this.state.local_video, 'local_video');
        }
        if (this.state.local_screen) {
            this.disableLocalTrack(this.state.local_screen, 'local_screen');
        }
    }

    disableLocalTrack(track, unsetState) {
        if (!track) return;
        if (track.stop) track.stop();
        if (_.get(this.state, 'room.instance.localParticipant.unpublishTrack')) {
            this.state.room.instance.localParticipant.unpublishTrack(track);
        }
        if (track.detach) track.detach();
        if (unsetState) {
            this.setState((state) => {
                state[unsetState] = false;
                return state;
            });
        }
    }

    newResearcherAlert() {
        this.setState({ newResearcherAlertStatus: Sound.status.STOPPED }, () => {
            this.setState({ newResearcherAlertStatus: Sound.status.PLAYING });
        });
    }

    renderRemoteTracks() {
        const tracks = _.chain(this.state.remote_tracks)
            .values()
            .filter(track => track && track.sid && track.isEnabled && !track.isSwitchedOff)
            .groupBy('kind')
            .value();

        if (!tracks.audio) tracks.audio = [];
        if (!tracks.video) tracks.video = [];

        if (tracks.video.length > 1 && _.get(this.state, 'dominant_user.Participant.videoTracks')) {
            const dominantUserTracks = [];
            this.state.dominant_user.Participant.videoTracks.forEach((Track) => {
               dominantUserTracks.push(Track.trackSid);
            });
            if (dominantUserTracks.length > 0) {
                tracks.video = _.filter(tracks.video, (track) => {
                    // console.log({ track, dominant_tracks: this.state.dominant_user.Tracks }, this.state.dominant_user.Tracks.indexOf(track.sid));
                    return dominantUserTracks.indexOf(track.sid) >= 0;
                });
            }
        }

        tracks.video = _.sortBy(tracks.video, (track) => {
            switch (track.name) {
                case 'client_desktop':
                    return -2;
                case 'client_video':
                    return -1;
                default:
                    return 1;
            }
        });

        return (
            <Fragment>
                <div className="media video" id="researcher-video-track">
                    {tracks.video[0] && <MediaTrack track={tracks.video[0]} id={tracks.video[0].sid} key={tracks.video[0].sid} fullscreen />}
                </div>
                <div className="media audio" id="researcher-audio-track">
                    {tracks.audio.map(track => <MediaTrack track={track} id={track.sid} key={track.sid} />)}
                </div>
            </Fragment>
        );
    }

    renderErrorMessages() {
        if (_.isEmpty(this.state.errors)) return null;
        if (this.state.errors.room_auth) {
            const dialogProps = {};
            const message = _.get(this.state.errors.room_auth, 'graphQLErrors.0.message');
            console.log('renderErrorDialog', message);
            if (message) {
                dialogProps.title = 'Error';
                dialogProps.body = message;
            } else {
                dialogProps.title = 'Error joining session';
            }
            dialogProps.body = (
                <Fragment>
                    {dialogProps.body && <p>{dialogProps.body}</p>}
                    <p>
                        <a href={HELP_URL} className="link">Help</a>
                        <br />
                        <Link to="/" className="link">Askable home page</Link>
                    </p>
                </Fragment>
            );

            return this.renderErrorDialog(dialogProps);
        } else if (this.state.errors.local_tracks) {
            return <Overlay.CamPermissionsUnblock />;
        } else if (this.state.errors.room_disconnected) {
            const dialogProps = {};
            dialogProps.title = 'Your session has ended';
            dialogProps.body = <p>{_.get(this.state.errors.room_disconnected, 'message', 'You have been disconnected from the video chat')}</p>;

            return this.renderErrorDialog(dialogProps);
        }

        for (const error in this.state.errors) {
            if (this.state.errors[error] && this.state.errors[error].body) {
                return this.ComponentrenderErrorDialog(this.state.errors[error]);
            }
        }
    }

    renderErrorDialog(dialogProps) {
        if (!dialogProps.body) return null;

        return (
            <Dialog
                customActions={[]}
                title={dialogProps.title}
                open
                modal={false}
                onCancel={null}
            >
                {dialogProps.body}
            </Dialog>
        );
    }

    renderScreenShareStatus() {
        const icon = (
            <svg viewBox="0 0 42 28" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon">
                <path d="M7 3.5H35V21H7V3.5ZM35 24.5C35.9283 24.5 36.8185 24.1313 37.4749 23.4749C38.1312 22.8185 38.5 21.9283 38.5 21V3.5C38.5 1.5575 36.925 0 35 0H7C5.0575 0 3.5 1.5575 3.5 3.5V21C3.5 21.9283 3.86875 22.8185 4.52513 23.4749C5.1815 24.1313 6.07174 24.5 7 24.5H0V28H42V24.5H35Z" />
            </svg>
        );
        if (this.state.local_screen) {
            return (
                <div className="media screenshare-status active">
                    {icon}
                    <span className="message">You are sharing your screen</span>
                    <div className="hover">
                        <span className="message">Reset screensharing?</span>
                        <button onClick={() => { this.createLocalTrack('screen'); }}>Reset</button>
                    </div>
                </div>
            );
        }
        if (this.state.errors.local_screen) {
            return (
                <div className="media screenshare-status error">
                    {icon}
                    <span className="message">Screen sharing is disabled</span>
                    <button onClick={() => { this.createLocalTrack('screen'); }}>Turn on</button>
                </div>
            );
        }
        if (this.state.local_screen === false && _.isEmpty(this.state.errors)) {
            return (
                <div className="media screenshare-status error">
                    {icon}
                    <span className="message">Your screensharing is off</span>
                    <button onClick={() => { this.createLocalTrack('screen'); }}>Turn on</button>
                </div>
            );
        }
        return (
            <div className="media screenshare-status inactive">
                {icon}
                <span className="message">Waiting to share screen</span>
                {(this.state.screenShareRequested && this.state.screenShareRequested < Date.now() - 15000) && <button onClick={() => { this.createLocalTrack('screen'); }}>Try again</button>}
            </div>
        );
    }

    render() {
        const waitingForResearcher = () => {
            if (_.get(this.state, 'errors.room_disconnected')) return 'Disconnected';
            if (!this.props.readyToConnect) return 'Waiting for researcher';
            if (_.isEmpty(this.state.researchers)) return 'Waiting for researcher';
            // if (_.isEmpty(this.state.observers)) return 'Waiting for researcher';

            return 'Connecting...';
        };

        if (_.has(this.state, 'errors.not_live')) return <Redirect to="/live" />;

        return (
            <div className="room-wrapper">
                <div className="room-tracks">
                    <div className="video-track researcher">
                        {_.get(this.state, 'remote_tracks_count', 0) < 1 && (
                            <div className="placeholder">
                                <span className="icon">{PlaceholderIconPerson}</span>
                                {waitingForResearcher()}
                            </div>
                        )}
                        {this.renderRemoteTracks()}
                    </div>
                    <div className="video-track participant">
                        <div className="media video" id="participant-video-track">
                            {this.state.local_video ? (
                                <MediaTrack track={this.state.local_video} id={this.state.local_video.id} key={this.state.local_video.id} />
                            ) : (
                                <div className="placeholder">Connecting camera</div>
                            )}
                        </div>
                        {this.renderScreenShareStatus()}
                        {/* <div className="media" id="participant-screen-track" /> */}
                    </div>
                    <div className="settings">
                        <Query
                            query={gql`query researcherUsers ($ids:[ID]) { userByIDs(ids:$ids) { _id  meta { identity { firstname lastname } } } }`}
                            variables={{ ids: _.chain(this.state).get('researchers', {}).keys().value() }}
                        >
                        {({ data }) => {
                            const users = [];

                            _.get(data, 'userByIDs', []).forEach((user) => {
                                users.push([user._id, `${_.get(user, 'meta.identity.firstname')}`]);
                            });
                            // for (const identity in _.get(this.state, 'observers', {})) {
                            //     users.push([identity, identity]);
                            // }

                            const label = users.length > 1 ? 'Researchers’ names:' : 'Researcher’s name:';

                            return (
                                <dl className="researcher">
                                    <dt>{label}</dt>
                                    {users.map(([_id, name]) => {
                                        const classes = [];
                                        if (users.length === 1 || _.get(this.state, 'dominant_user._user_id') === _id) {
                                            classes.push('speaking');
                                        }
                                        return (
                                            <dd
                                                key={_id}
                                                className={classes.join(' ')}
                                            >
                                                {name}
                                            </dd>
                                        );
                                    })}
                                </dl>
                            );
                        }}
                        </Query>
                        <SettingsButton
                            track_video={this.state.local_video}
                            track_audio={this.state.local_audio}
                            track_screen={this.state.local_screen}
                            createLocalTrack={this.createLocalTrack}
                        />
                    </div>
                </div>
                {(this.props.messages || this.props.messages === false) && (
                    <div className="room-messages">
                        {this.props.messages || null}
                    </div>
                )}

                {this.renderErrorMessages()}
                {this.state.desktopRequestPrompt && <Overlay.DesktopPromptChrome />}
                <Sound
                    url="/assets/askable-live-connection.mp3"
                    playStatus={this.state.newResearcherAlertStatus || Sound.status.PAUSED}
                    onFinishedPlaying={() => {
                        if (this.state.newResearcherAlertStatus === Sound.status.PLAYING) {
                            this.setState({ newResearcherAlertStatus: Sound.status.STOPPED });
                        }
                    }}
                />
            </div>
        );
    }
}

const PlaceholderIconPerson = (
    <svg width="91" height="91" viewBox="0 0 91 91" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M45.5 0C51.5337 0 57.3202 2.39687 61.5867 6.66332C65.8531 10.9298 68.25 16.7163 68.25 22.75C68.25 28.7837 65.8531 34.5702 61.5867 38.8367C57.3202 43.1031 51.5337 45.5 45.5 45.5C39.4663 45.5 33.6798 43.1031 29.4133 38.8367C25.1469 34.5702 22.75 28.7837 22.75 22.75C22.75 16.7163 25.1469 10.9298 29.4133 6.66332C33.6798 2.39687 39.4663 0 45.5 0ZM45.5 11.375C42.4832 11.375 39.5899 12.5734 37.4567 14.7067C35.3234 16.8399 34.125 19.7332 34.125 22.75C34.125 25.7668 35.3234 28.6601 37.4567 30.7933C39.5899 32.9266 42.4832 34.125 45.5 34.125C48.5168 34.125 51.4101 32.9266 53.5433 30.7933C55.6766 28.6601 56.875 25.7668 56.875 22.75C56.875 19.7332 55.6766 16.8399 53.5433 14.7067C51.4101 12.5734 48.5168 11.375 45.5 11.375ZM45.5 51.1875C60.6856 51.1875 91 58.7519 91 73.9375V91H0V73.9375C0 58.7519 30.3144 51.1875 45.5 51.1875ZM45.5 61.9937C28.6081 61.9937 10.8063 70.2975 10.8063 73.9375V80.1937H80.1937V73.9375C80.1937 70.2975 62.3919 61.9937 45.5 61.9937Z" fill="#4F4F4F"/>
    </svg>
);

export default ChatRoom;
