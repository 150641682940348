import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { graphql, compose } from 'react-apollo';
import { Input, Button, LoadingOverlay, Snackbar, Wrapper } from 'components/common';
import { withRouter } from 'react-router';
import { localStorage } from 'lib/storage';
import { utils } from 'lib/utils';

// Queries
import fetchPasswordRequestChangeByRequestID from 'data/queries/password_request_change/fetchPasswordRequestChangeByRequestID';

// Mutation
import changePasswordByRequestId from 'data/mutations/password_request_change/changePasswordByRequestID';
import loginUser from 'data/mutations/loginUser';

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            password1: '',
            password2: '',
            error: '',
            loading: false,
        };

        this.onSaveNewPassword = this.onSaveNewPassword.bind(this);
        this.renderResetPassword = this.renderResetPassword.bind(this);

        this.queryParams = utils.urlParams(props.location.search) || {};
        if (this.queryParams.targetPath) {
            localStorage.save('tagetPath', this.queryParams.targetPath);
            props.history.replace(props.location.pathname);
        }
    }

    onSaveNewPassword(event) {
        if (event) event.preventDefault();

        this.setState({
            loading: true,
            error: ''
        });
        // It should first test if password1 is the same as password 2
        if (this.state.password1 !== this.state.password2) {
            this.setState({
                error: 'Make sure your password matches the confirmation',
                loading: false
            });
        } else {
            const { passwordChangeRequestByRequestID } = this.props.passwortRequestChangeData;
            // After all the tests -> Change Password
            this.props.changePasswordByRequestID(passwordChangeRequestByRequestID.email, this.props.match.params.requestId, this.state.password1)
                .then(() => {
                    this.setState({ error: '' });
                    this.snackbar.open('Password updated successfully');
                })
                .then(() => {
                    return this.props.loginUser(passwordChangeRequestByRequestID.email, this.state.password1)
                        .then(({ data }) => {
                            localStorage.save('connectedParticipant', data.login._id);
                            localStorage.save('participant_access_token', data.login.access_token);
                            localStorage.save('participant_details', JSON.stringify({
                                email: data.login.email,
                                name: {
                                    firstname: (data.login.meta && data.login.meta.identity && data.login.meta.identity.firstname) || '',
                                    lastname: (data.login.meta && data.login.meta.identity && data.login.meta.identity.lastname) || ''
                                }
                            }));
                            localStorage.delete('queryUserId');
                        });
                })
                .then(() => {
                    this.setState({ loading: false, redirectTo: utils.privateRouteResume() });
                })
                .catch((error) => {
                    this.setState({
                        error,
                        loading: false
                    });
                });
        }
    }

    renderResetPassword() {
        const { passwordChangeRequestByRequestID } = this.props.passwortRequestChangeData;
        if (passwordChangeRequestByRequestID.expired) {
            return (
                <div className="textCenter">
                    <h2 className="error">Reset link is expired.</h2>
                    <p><Link to="/forgotPassword" className="link">Get a new link</Link></p>
                </div>
            );
        }
        return (
            <form onSubmit={this.onSaveNewPassword}>
                <Input
                    name="password1"
                    placeholder="New password"
                    type="password"
                    value={this.state.password1}
                    onChange={value => this.setState({ password1: value })}
                />
                <p className="font--light">Tips: Use at least 8 characters. Don’t re-use passwords from other websites or include obvious words like your name or email.</p>
                <Input
                    name="password2"
                    placeholder="Confirm new password"
                    type="password"
                    value={this.state.password2}
                    onChange={value => this.setState({ password2: value })}
                />
                <div className="mtop40">
                    <Button
                        label="Save & continue"
                        labelColor="#fff"
                        bgColor="#FF5266"
                        className="btnNext"
                        type="submit"
                        disabled={!this.state.password1 || !this.state.password2}
                    />
                </div>
                {utils.isAuthenticated() && (
                    <div className="mtop40 textCenter">
                        Don’t want to reset your password now?
                        <br />
                        <Link
                            to={localStorage.get('tagetPath') || '/'}
                            className="link"
                        >Continue to Askable
                        </Link>.
                    </div>
                )}
            </form>
        );
    }

    render() {
        if (this.props.passwortRequestChangeData.loading) return <LoadingOverlay />;
        if (this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;

        // If the request ID is not valid then the query won't return anything and therefore redirect to the login page
        const { passwordChangeRequestByRequestID } = this.props.passwortRequestChangeData;
        if (!passwordChangeRequestByRequestID) {
            return <Redirect to="/login" />;
        }

        return (
            <div className="resetPasswordContainer">
                <Wrapper className="settingsInnerWrapper">
                    {this.state.loading &&
                        <LoadingOverlay
                            style={{ opacity: 0.8 }}
                        />
                    }
                    <div className="textCenter">
                        <img src="/askable-logo.svg" alt="Askable" width="200" className="mbottom40" />
                        <h1>Set your new password</h1>
                    </div>
                    <div className="mtop30">
                        {this.state.error &&
                            <span className="error">{this.state.error}</span>
                        }
                        {this.renderResetPassword()}
                    </div>
                </Wrapper>
                <Snackbar
                    ref={(ref) => { this.snackbar = ref; }}
                />
            </div>
        );
    }
}

const passwordRequestChangeContainer = graphql(fetchPasswordRequestChangeByRequestID, {
    name: 'passwortRequestChangeData',
    options: props => ({
        variables: { request_id: props.match.params.requestId },
    }),
});

const changePasswordByRequestIDContainer = graphql(changePasswordByRequestId, {
    props: ({ mutate }) => ({
        changePasswordByRequestID: (email, request_id, new_password) => mutate({
            variables: { email, request_id, new_password }
        }),
    })
});

const loginUserMutation = graphql(loginUser, {
    props: ({ mutate }) => ({
        loginUser: (email, password) => mutate({
            variables: { email, password }
        }),
    }),
});

export default withRouter(compose(
    passwordRequestChangeContainer,
    changePasswordByRequestIDContainer,
    loginUserMutation
)(ResetPassword));
