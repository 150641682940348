import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { utils } from 'lib/utils';
import _ from 'lodash';
import React, { FC, PropsWithChildren } from 'react';
import { Query } from 'react-apollo';
import fetchUserById from '../../../data/queries/user/fetchUserById';

import './PayoutMethodWarningBanner.scss';

const PayoutDetailsLink = ({ children }: PropsWithChildren) => {
  return (
    <a
      href="#menu=payout"
      onClick={e => {
        e.preventDefault();
        utils.setMenuState('menu=payout');
      }}
    >
      {children}
    </a>
  );
};

const PayoutMethodWarningBanner: FC = () => {
  const { isAuthenticated } = useKindeAuth();
  if (!isAuthenticated) {
    return null;
  }

  return (
    <Query query={fetchUserById}>
      {({ data }: { data: unknown }) => {
        const userLoaded = !!_.get(data, 'userByID._id');
        if (!userLoaded) {
          return null;
        }

        const oldPaypal =
          _.get(data, 'userByID.settings.billing.preferred') === 'paypal' &&
          _.get(data, 'userByID.settings.billing.paypal') &&
          !_.get(data, 'userByID.settings.billing.paypal_details._id');

        const preferred = _.get(data, 'userByID.settings.billing.preferred') || '_none';
        const infoMissing = !_.get(data, `userByID.settings.billing.${preferred}`);

        if (oldPaypal) {
          return (
            <div className="payout-method-warning _toolbar headerBannerLinks">
              <div className="bannnerItem">
                <span>
                  Please <PayoutDetailsLink>update your PayPal details</PayoutDetailsLink> to avoid interruptions to your incentive payments.
                </span>
                <span>
                  <a href="https://help.askable.com/en/articles/9488016" target="_blank">
                    Learn&nbsp;more
                  </a>
                </span>
              </div>
            </div>
          );
        }

        if (preferred === 'paypal' && _.get(data, 'userByID.settings.billing.paypal_details.status') !== true) {
          return (
            <div className="payout-method-warning _toolbar headerBannerLinks">
              <div className="bannnerItem">
                <span>
                  Please verify your PayPal account to avoid interruptions to your incentive payments.
                </span>
                <span>
                  <PayoutDetailsLink>
                    Verify&nbsp;account
                  </PayoutDetailsLink>
                </span>
              </div>
            </div>
          );
        }

        if (infoMissing) {
          return (
            <div className="payout-method-warning _toolbar headerBannerLinks">
              <div className="bannnerItem">
                <span>
                  Please <PayoutDetailsLink>set a payout method</PayoutDetailsLink> to avoid interruptions to your incentive payments.
                </span>
              </div>
            </div>
          );
        }

        return null;
      }}
    </Query>
  );
};

export { PayoutMethodWarningBanner };
