import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';
import _ from 'lodash';

import { graphql, compose } from 'react-apollo';
import { Redirect } from 'react-router-dom';

import { BOOKING_TYPE } from 'lib/data/constants';

import BookingMessages from 'components/messages/messagesBookingView';
import Room from './askable-live-chat-room.view';
import OnlineUsersListener from './askable-live-online-users-listener.view';

class SessionRoom extends Component {
    constructor() {
        super();
        this.state = {
            // roomSetupDetails: {},
            // onlineUsers: [],
        };

        this.roomSetup = this.roomSetup.bind(this);
        this.userDisconnect = this.userDisconnect.bind(this);
    }
    componentDidMount() {
        this.roomSetup();
        window.addEventListener('beforeunload', this.userDisconnect);
    }
    componentWillUnmount() {
        if (this.disconnectTimeout) {
            clearTimeout(this.disconnectTimeout);
        }
        this.userDisconnect();
    }
    roomSetup() {
        // this.props.roomSetup(this.props._booking_id, this.props._session_id)
        //     .then((result) => {
        //         this.setState({ roomSetupDetails: _.get(result, 'data') || {} });
        //     });
        this.props.onlineStatus(this.props._booking_id, this.props._session_id, true);
    }
    userDisconnect() {
        this.props.onlineStatus(this.props._booking_id, this.props._session_id, false);
    }
    render() {
        const { bookingDetails } = this.props;
        if (!bookingDetails) return <Redirect to="/live/" />;
        if (bookingDetails.loading) return null;

        const booking = _.get(bookingDetails, 'bookingByID');

        if (!bookingDetails.loading && !(booking.type === BOOKING_TYPE.REMOTE && _.get(booking, 'config.remote.askable_live'))) {
            return <Redirect to={`/booking/${this.props._booking_id}`} />;
        }

        if (this.state.disconnected) {
            // TODO
            return 'THE SESSION HAS FINISHED';
        }

        let session = {};
        if (booking && booking.session) {
            session = _.find(booking.session, ['_id', this.props._session_id]);
        }

        return (
            <Fragment>
                <Room
                    // room={_.get(this.state.roomSetupDetails, 'askableLiveRoomSetup') || null}
                    roomSetup={() => this.props.roomSetup(this.props._booking_id, this.props._session_id)}
                    messages={!bookingDetails.error && (
                        <BookingMessages
                            match={{ params: { id: this.props._booking_id } }}
                            header={null}
                            wrapperHeader={null}
                        />
                    )}
                    readyToConnect={!!this.state.readyToConnect}
                    disconnectionMessage={this.state.disconnectionMessage}
                />
                <OnlineUsersListener
                    _booking_id={this.props._booking_id}
                    _session_id={this.props._session_id}
                    pollInterval={10000}
                    setOnline={(online = true) => this.props.onlineStatus(this.props._booking_id, this.props._session_id, online)}
                    onUpdate={(users) => {
                        if (users.length > 0) {
                            this.setState({ readyToConnect: true, disconnectionMessage: null });
                            if (this.disconnectTimeout) {
                                console.log('Room has other users - don’t disconnect');
                                clearTimeout(this.disconnectTimeout);
                                this.disconnectTimeout = null;
                            }
                        } else if (this.state.readyToConnect) {
                            if (this.disconnectTimeout) {
                                clearTimeout(this.disconnectTimeout);
                            }
                            const disconnectTimeout = (1000 * 60 * 15) + Math.max(0, session.start - Date.now());
                            console.log(`Room is empty, disconnecting at ${new Date(Date.now() + disconnectTimeout).toLocaleTimeString()}`);
                            this.disconnectTimeout = setTimeout(() => {
                                this.setState({ disconnectionMessage: 'The facilitator has ended the chat' });
                            }, disconnectTimeout);
                        }
                    }}
                />
            </Fragment>
        );
    }
}

const bookingDetailsContainer = graphql(
    gql`query askableLiveBookingDetails ($_booking_id: ID!) {
        bookingByID(id: $_booking_id) {
            _id type
            session { _id start end }
            config {
                contact { name }
                remote { askable_live }
            }
        }
    }`,
    {
        name: 'bookingDetails',
        options: ({ _booking_id }) => ({
            variables: { _booking_id },
        }),
    }
);
const roomSetupContainer = graphql(
    gql`mutation askableLiveRoomSetup ($_booking_id:ID!, $_session_id:ID!) {
        askableLiveRoomSetup(booking_id: $_booking_id, session_id: $_session_id) {
            token
            room { sid uniqueName status }
        }
    }`,
    {
        props: ({ mutate }) => ({
            roomSetup: (_booking_id, _session_id) => mutate({
                variables: { _booking_id, _session_id }
            }),
        }),
    }
);
const onlineStatusContainer = graphql(
    gql`mutation askableLiveOnlineStatus ($_booking_id:ID!, $_session_id:ID!, $status: Boolean!) {
        askableLiveOnlineStatus(booking_id: $_booking_id, session_id: $_session_id, online: $status) {
            _user_id updated online
            User { _id email type { client participant } }
        }
    }`,
    {
        props: ({ mutate }) => ({
            onlineStatus: (_booking_id, _session_id, status) => mutate({
                variables: { _booking_id, _session_id, status }
            }),
        }),
    }
);

export default compose(bookingDetailsContainer, roomSetupContainer, onlineStatusContainer)(SessionRoom);
